import { useRef } from "react";
import { Box, Button } from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { IoAlertCircle } from "react-icons/io5";
import { useAlerts } from "../../contexts/SocketContext";
import AlertRow from "./AlertRow";
import { SCROLLBAR_STYLES } from "../../constants";

function DisplayAlerts() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const { alerts, removeAlert } = useAlerts();

  return (
    <>
      {alerts.length > 0 && (
        <Button
          leftIcon={<IoAlertCircle />}
          ref={btnRef}
          colorScheme="red"
          w="100%"
          onClick={onOpen}
          borderRadius={0}
          margin={1}
        >
          Alerts: {alerts.length}
        </Button>
      )}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Alerts</DrawerHeader>
          <DrawerBody>
            <Box
              flexDirection={"column"}
              overflowY="scroll"
              h="500px"
              css={SCROLLBAR_STYLES}
            >
              {alerts.map((alert, index) => (
                <AlertRow
                  key={`alert_${index}`}
                  alert={alert}
                  removeAlert={removeAlert}
                />
              ))}
            </Box>
          </DrawerBody>
          <DrawerFooter position={"absolute"} bottom={0} right={0}>
            <Button colorScheme={"teal"} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DisplayAlerts;
