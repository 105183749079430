import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);


export const PieChart = ({ chartData }) => {
    console.log(chartData);
  return (
    <div style={{height:"40vh" ,width:"100vw"}}>
      <Doughnut
        data={chartData}
        font="16px"
        
        options={{
            responsive:true,
           maintainAspectRatio:false,
           
           height:"350px",
          plugins: {
            title: {
              display: true,
              text: "Exhibit Tickets Grouped By status"
            },
            legend: {
              display: true,
              position: "bottom"
           }
          },
          
            
        }}
      />
    </div>
  );
};