import axios from "axios"
// import ShowExhibits from "./ShowExhibits"
import { useState,useEffect } from "react"
import { SERVER_URL } from "../../constants"
import {Flex,FormControl,FormLabel,Input,Stack,Button} from "@chakra-ui/react"
import { Link } from "react-router-dom"
export default function AddExhibit(){
    const [error,setError]=useState(false);
    const [message,setMessage]=useState('')
    const [exhibitInfo,setExhibitInfo]=useState({
        name:'',
        gallery:''
    })


    function handleExhibitChange(e){
            setError(false);
	    setMessage('');
            setExhibitInfo({
                ...exhibitInfo,
                [e.target.name]:e.target.value
            })
    }

    function MakeNamesUniqueStyle(p){
        
        let p_updated = p.trim();
        const found = p_updated.match(/^./);
        
        let c = found[0].toUpperCase()+p_updated.slice(1).toLowerCase();
        return c
    }

    async function addExhibit(e){
        e.preventDefault();
        try{
            exhibitInfo.gallery=MakeNamesUniqueStyle(exhibitInfo.gallery);
            const isAdded = await axios.post(`${SERVER_URL}/api/v1/addexhibit`,exhibitInfo,{withCredentials:true});
            if(isAdded){
                setMessage('Exhibit added succesfully');
                setExhibitInfo({
                   
                    name:'',
                    gallery:''
                })
                

            }
        }
        catch(err){
            setError(true);
            setMessage(err.message);
        }

    }

    


    return (
        <>
            <Flex width="100vw" height="100vh" justifyContent ="space-around" alignItems="center" direction="column"  bgColor="rgb(0, 82, 204)">
                <Flex direction="column" alignItems="center" justifyContent="center" bgColor="white" borderWidth={1} borderRadius={15} boxShadow="lg">
                    <Stack direction={["column","row"]} spacing="10px"margin="5px" >
                    <FormControl isRequired>
                                <FormLabel>Gallery</FormLabel>
                                
                                    
                                    
                                    <Input type="text" name="gallery" variant='outline' placeholder='Health' value={exhibitInfo.gallery}  onChange={(event)=>{handleExhibitChange(event)}}/>
                                
                               
                    </FormControl>
                        <FormControl isRequired>
                                <FormLabel>ExhibitName</FormLabel>
                                
                                    
                                    
                                    
                                    <Input type="text" name="name" variant='outline' placeholder='FredtheRobot' value={exhibitInfo.name}  onChange={(event)=>{handleExhibitChange(event)}}/>
                                
                            
                        </FormControl>
                    </Stack>
                    <Button onClick={(event)=>addExhibit(event)} colorScheme="green">
                        Add
                    </Button>

                </Flex>
                { (error||message)&&<h2>{message}</h2>}
                <Link to='/exhibits'><Button>Back</Button></Link>
            </Flex>
        </>
    )
}
