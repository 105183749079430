
import { useAuth } from "../../contexts/OAuthContext"
import {Center,Button} from "@chakra-ui/react";
import {SiMicrosoftoutlook} from 'react-icons/si'
import { useNavigate ,useLocation} from "react-router-dom";
import { useEffect } from "react";



function Login({setMenu}){ 
    
    console.log(setMenu);
    const {Login} = useAuth();
    console.log(Login,'login')
    const navigate = useNavigate();
    const { state } = useLocation();

    const handleLogin = ()=>{
        const result = Login();
        console.log(result,'logincomp');
        if(result){
            console.log('here yes',state?.path);
            navigate(state?.path || '/');
        }
    }

    useEffect(()=>{
        setMenu(false);
    },[]);

    return (
        <Center width="100vw" height ="100vh" bgColor="rgb(0, 82, 204)">

            <Button color="rgb(23, 43, 77)" leftIcon={<SiMicrosoftoutlook/>} onClick={handleLogin} >
                Login with Outlook
            </Button>

        </Center>
    )

}

export default Login