import { useUser } from "../contexts/OAuthContext";

import { useLocation } from 'react-router-dom'

import { Navigate } from "react-router-dom";

function CheckLogin({children}){
   
    const user = useUser()
    
    const { state } = useLocation();
  
    
    const path= state.path

    return (
        !user?children:<Navigate to={path}replace/>
    )

}
export default CheckLogin;