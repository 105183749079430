import { SelectColumnFilter } from "./SelectColumnFilter";
import { SelectColumnArrayFilter } from "./SelectColumnArrayFilter";
import { Button } from "@chakra-ui/react";

const columns =[
    {
        Header:'Id',
        accessor:'_id',
        Cell:({ value }) => {return(<a href={`/facilityticket/${value}`}> <Button colorScheme="facebook">ViewTicket</Button></a>) },
        disableFilters:true
    },
    {
        Header:'RaisedBy',
        accessor:'user_emailaddress',
        Cell:({value})=>{return value.slice(0,value.indexOf('@'))},
        disableFilters:true
    },
    {
        Header:'ticketTitle',
        accessor:'ticketTitle',
        disableFilters:true
        
    },
    {
        Header:'CreatedOn',
        accessor:'createdAt',
        disableFilters:true
        
    },
    {
        Header:'UpdatedOn',
        accessor:'updatedAt',
        Cell:({value})=>{if(!value) return '-' ;else return value },
        disableFilters:true
        
    },
    {
        Header:'AssignedTo',
        accessor:'assignedTo',
        Cell:({value})=>{if(value.length===0) return 'none' ;else  return `${value}  ` },
        Filter:SelectColumnArrayFilter,
        filter:'includes',
        disableSortBy:true


        
    },
    {
        Header:'Status',
        accessor:'status',
        Cell:({value})=>{if(!value) return 'none'; else return value },
        Filter:SelectColumnFilter,
        filter:'includes',
        disableSortBy:true
    },
    {
        Header:'Building Area',
        accessor:'buildingArea',
        Filter:SelectColumnFilter,
        filter:'includes',
        disableSortBy:true
        
    }
    ]

export {columns}