import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
} from "@chakra-ui/react";
import { SCROLLBAR_STYLES } from "../../../../constants";
import { useRequests } from "../../../../contexts/SocketContext";

const Results = ({ hostname }) => {
  const { requests, removeTrackingRequest } = useRequests();
  return (
    <Accordion
      p={2}
      h="200px"
      overflow={"hidden scroll"}
      css={SCROLLBAR_STYLES}
    >
      {requests
        .filter((r) => r.whichScreen === hostname)
        .map((r, index) => (
          <AccordionItem key={`request_${index}`}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {r.message}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              h="100px"
              css={SCROLLBAR_STYLES}
              overflow={"hidden scroll"}
            >
              {r.message}
              {r.data}
              {JSON.stringify(r.err)}
              <Button
                onClick={() => removeTrackingRequest(r)} // remove action from list
              >
                Remove
              </Button>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
};

export default Results;
