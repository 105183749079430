import axios from "axios";
import { SERVER_URL } from "../constants";

const useSwitchLights = () => {
  function switchLights(which, onOrOff) {
    console.log("switch light", which, onOrOff);
    axios
      .post(`${SERVER_URL}/state/setLighting`, { which, onOrOff })
      .then(({ message }) => {
        console.log(message);
      })
      .catch(({ message }) => {
        console.log(message);
      });
  }

  return switchLights;
};

export default useSwitchLights;
