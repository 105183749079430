import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const AlertRow = ({ alert, removeAlert }) => (
    <Alert status="error" variant={"left-accent"} marginBottom={1}>
      <AlertIcon />
      <AlertTitle>{alert.title}</AlertTitle>
      <AlertDescription>{alert.description}</AlertDescription>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={() => removeAlert(alert)}
      />
    </Alert>
);

export default AlertRow;
