import { useAuth } from "../contexts/OAuthContext";
// import Login from "../pages/Auth/Login";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'


function CheckAuth({children}){
    const {user} = useAuth()
    const location = useLocation();
    console.log(user);

    

    return (
        user?children:<Navigate to="/login" replace state={{ path: location.pathname }}/>
    )

}
export default CheckAuth;