import axios from "axios";
import { SERVER_URL } from "../constants";

const useSwitchLightFloorPlan = () => {
  function switchLights(ip,command,onOrOff,port) {
    console.log("switch light by API", ip,command,onOrOff,port);
    axios
      .post(`${SERVER_URL}/state/setFloorPlanLighting`, { ip,command,onOrOff,port })
      .then(({ message }) => {
        console.log(message);
      })
      .catch(({ message }) => {
        console.log(message);
      });
  }

  return switchLights;
};

export default useSwitchLightFloorPlan;
