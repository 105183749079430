import { Center, Flex, Input, Select } from "@chakra-ui/react";
import useGalleries from "../../../../hooks/useGalleries";

const SearchFilter = ({ name, gallery, setName, setGallery }) => {
  const galleries = useGalleries();
  return (
    <Center
      w="97vw"
      bg="transparent"
      zIndex={1}
      bottom={10}
      left={0}
      position={"fixed"}
      h="40px"
    >
      <Flex
        bg="white"
        p={2}
        w={"60vw"}
        border={"3px solid teal"}
        borderRadius={20}
        justifyContent="space-between"
      >
        <Input
          value={name}
          placeholder="Type Specific Name"
          marginRight={5}
          id="name"
          onChange={(e) => setName(e.target.value)}
        />
        <Select
          placeholder={gallery}
          onChange={(e) => {
            setGallery(e.target.value === "Clear" ? "" : e.target.value);
          }}
        >
          {[...galleries, ""].map(
            (possibleValue, index) =>
              possibleValue !== gallery && (
                <option key={`gallery_${index}`}>
                  {possibleValue || "Clear"}
                </option>
              )
          )}
        </Select>
      </Flex>
    </Center>
  );
};
export default SearchFilter;
