import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
import { Flex } from "@chakra-ui/react";

ChartJS.register(...registerables);

export const BarChart = ({ chartData }) => {
    console.log(chartData);
  return (
    <Flex height="50vh" width="100vw" >
      <Bar
        data={chartData}
        font="16px"
        width="200px"
        height="500px"
        options={{
          plugins: {
            title: {
              display: true,
              text: "Exhibit Fault Reporting Overview"
            },
            legend: {
              display: true,
              position: "bottom"
           }
          },
          responsive:true,
            maintainAspectRatio: false 
        }}
      />
    </Flex>
  );
};