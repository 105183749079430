import axios from "axios";
import { SERVER_URL } from "../constants";

const useSwitchLightswithBrightness = () => {
  function switchLights(whichFloor,whichLight, level) {
    console.log("switch light brightness from FE", whichFloor,whichLight, level);
    axios
      .post(`${SERVER_URL}/state/setFloorLightingBrightness`, { whichFloor,whichLight,level })
      .then(({ message }) => {
        console.log(message);
      })
      .catch(({ message }) => {
        console.log(message);
      });
  }

  return switchLights;
};

export default useSwitchLightswithBrightness;
