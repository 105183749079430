import { Grid, GridItem, Heading, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  POSSIBLE_STATES,
  ALL_LIGHTS_KEY,
  SERVER_URL,
} from "../../../../constants";
import { SelectInput, SwitchInput } from "../../../../components/Input";
import useChangeState from "../../../../hooks/useChangeState";
import useSwitchLights from "../../../../hooks/useSwitchLights";
import axios from "axios";

const GlobalControls = () => {
  const [currentGlobalState, setCurrentGlobalState] = useState("Startup");
  const { changeState, changeAppPersistence, changeSleep } = useChangeState();
  const [appPersistence, setAppPersistence] = useState(false);
  const [screenState, setScreenState] = useState(false);
  const switchLights = useSwitchLights();

  useEffect(() => {
    async function getGalleryStateDetails() {
      const res = await axios.get(`${SERVER_URL}/state/get/global`);
      setCurrentGlobalState(res.data.state);
      setScreenState(res.data.sleep);
      setAppPersistence(res.data.appPersistence);
    }

    getGalleryStateDetails();
  }, []);

  return (
    <>
      <Heading>Global Controls</Heading>

      <Grid
        w="100%"
        templateColumns={"1fr 15% 15%"}
        paddingY={2}
        borderBottom={"1px solid"}
        borderBottomColor={"gray.200"}
      >
        <GridItem>Global Lights</GridItem>
        <GridItem>
          <Button onClick={() => switchLights(ALL_LIGHTS_KEY, "on")}>on</Button>
        </GridItem>
        <GridItem>
          <Button onClick={() => switchLights(ALL_LIGHTS_KEY, "off")}>
            off
          </Button>
        </GridItem>
      </Grid>

      <SelectInput
        title={"Global State"}
        possibleValues={POSSIBLE_STATES}
        value={currentGlobalState}
        setValue={setCurrentGlobalState}
        onChange={(e) => changeState("global", "global", e.target.value)}
      />

      <SwitchInput
        title={"Current Sleep State"}
        value={screenState}
        setValue={setScreenState}
        onChange={(this_sleep) => changeSleep("global", "global", this_sleep)}
      />
      <SwitchInput
        value={appPersistence}
        setValue={setAppPersistence}
        title={"App Persistence"}
        onChange={(this_appPersistence) =>
          changeAppPersistence("global", "global", this_appPersistence)
        }
      />
    </>
  );
};

export default GlobalControls;
