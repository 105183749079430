import { useState, useEffect } from "react"
import { BarChart } from "./BarChart"
import axios from "axios";
import { SERVER_URL } from "../../constants";
import { PieChart } from "./PieChart";
import { Flex } from "@chakra-ui/react";

export default function ExhibitCharts() {
  // ...

  const [chartData, setChartData] = useState({})
  const [chartStatusData, setChartStatusData] = useState({})
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {
    
    const fetchExhibits = async () => {
    try{
   
    const result =  await axios.get(`${SERVER_URL}/api/v1/exhibitsCount`,{withCredentials:true});
    const result2 =  await axios.get(`${SERVER_URL}/api/v1/exhibitstatus`,{withCredentials:true});


      const data = result;
      const data2 = result2;
      console.log(data);
      console.log(data2);
      if(data && data2){
      
      setChartData({
        labels: data.data.tickets.map((exhibit) => exhibit._id),
        datasets: [
          {
            label: "No of Tickets by exhibit",
            data: data.data.tickets.map((exhibit) => exhibit.exhibitCount),
            backgroundColor: [
              "#ffbb11",
              "#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0"
            ]
          }
        ]
      })

      setChartStatusData({
        labels: data2.data.tickets.map((exhibit) => exhibit._id),
        datasets: [
          {
            label: "No of Tickets by status",
            data: data2.data.tickets.map((exhibit) => exhibit.Count),
            backgroundColor: [
              "Blue",
              'Green',
              "Red",
              "#ffbb11",
              "#2a71d0"
            ]
          }
        ]
      })
      setIsLoading(true);
    }
    }catch(err){
        setIsLoading(false);
        console.log(err);
    }
}
    fetchExhibits()
  }, []);


  if(!isLoading){
    return(
        <>
        <h2>wait please,till we load charts for you</h2>
        </>
    )
  }else{
    return (
     <Flex direction="column" gap="5px">
       <BarChart chartData={chartData} />
       <PieChart chartData={chartStatusData}/>
     </Flex>
    )
  }

//   return (
//     <>

//     { !!isLoading?(<h2>Please wait while we load ur charts</h2>):
//      (<div >
//       <BarChart chartData={chartData} />
//      </div>)
//     }
//     </>
//   )
}
