import { useState, useEffect } from "react";
import axios from "axios";
import { SCROLLBAR_STYLES, SERVER_URL } from "../../constants";
import {
  Grid,
  GridItem,
  Flex,
  Button,
  Heading,
  
  Container,
  FormControl,
  FormHelperText,
  Input
} from "@chakra-ui/react";
import useSwitchLights from "../../hooks/useSwitchLights";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/OAuthContext";

const LightsAndProjectorsRow = ({ id, name, switchFunc }) => (
  <Grid
    w="100%"
    templateColumns={"1fr 20% 20%"}
    paddingY={2}
    borderBottom={"1px solid"}
    borderBottomColor={"gray.200"}
  >
    <GridItem>{name}</GridItem>
    <GridItem>
      <Button onClick={() => switchFunc(id, "on")}>on</Button>
    </GridItem>
    <GridItem>
      <Button onClick={() => switchFunc(id, "off")}>off</Button>
    </GridItem>
  </Grid>
);

const LightsAndProjectors = () => {
  const [lights, setLights] = useState([]);
  const {isAdmin}=useAuth();
  

  const navigate = useNavigate()
  const switchLights = useSwitchLights();
  const [inputCommand, setinputCommand] = useState('');
  const isError = inputCommand === '';
  useEffect(() => {
    let mounted=true;

    async function getLights(){
    try{
      
      
      
    const result = await axios.get(`${SERVER_URL}/state/getLighting`)
    if(result){
      

      console.log(result,'....')
      
      setLights(result.data.lights);
    }
    
  }
  catch(err){
    console.log(err.response.status,err);
  
}
    }
mounted&&getLights();

    return (()=>{
        mounted = false;
    })
    
    

  }, []);

   async function sendCommands(e) {
    e.preventDefault();
    console.log(inputCommand);
    const result = await axios.post(`${SERVER_URL}/state/sendCommand`, { command: inputCommand })
    console.log(result, 'from fronten');
    if (result) setinputCommand(''); console.log('statereseeted', inputCommand)

  }; 
  
  function handleAllLights(){
      navigate('/AllLights');
  }

   function handleExhibitLights(){
    navigate('/exhibitpowerlights');
  }

  return (
    <>
    {!isAdmin?<h2>You are not authorized to view this page</h2>:
    (<Flex w="100%" h="100%" alignItems="center" justifyContent="center" paddingTop={10} direction={["column","row"]}>
      <Container
        w="95%"
        h="600px"
        border={"solid"}
        borderColor={"gray.200"}
        padding={5}
        margin={5}
        borderRadius={"md"}
      >
        <Heading>Lights</Heading>
        <Flex
          flexDirection= "column" 
          h="500px"
          overflowY={"scroll"}
          css={SCROLLBAR_STYLES}
        >
          {lights.map(({ id, name }, index) => (
            <LightsAndProjectorsRow
              key={`lights_${index}`}
              id={id}
              name={name}
              switchFunc={switchLights}
            />
          ))}
        </Flex>
      </Container>
      <Flex flexDirection= "column" flexWrap={["nowrap","wrap"]}   h={["600px","800px"]} w={["95%",'50%']}>
      <Container w={["90%","50%"]} height="300px" border={"solid"}
        borderColor={"gray.200"}
        padding={5}
        margin={5}
        borderRadius={"md"}>


        <FormControl h="50vh" w="50vw" isInvalid = {isError} >
          <Grid templateRows={"50% 50%"}>
            <GridItem>Light Commands</GridItem>
            <GridItem>
              <Input
                borderColor={"teal.300"}
                w={["80%","80%","80%","45%"]}
                id="command"
                name="command"
                value={inputCommand}
                onChange={(e) => setinputCommand(e.target.value)}
              />
            </GridItem>
          </Grid>

          <FormHelperText>
            Enter a command to manipulate lights if needed
          </FormHelperText>

          <Button
            marginTop="5"
            colorScheme="teal"
            variant="solid"
            type='submit'
            onClick={sendCommands}
          >
            Send Command
          </Button>
        </FormControl>

      </Container>
      <Container  w={["90%","50%"]} height="200px" border={"solid"}
        borderColor={"gray.200"}
        padding={5}
        margin={5}
        
        borderRadius={"md"}>
         <span> This button fixes emergency exit lights, stairs, and back hallway:</span><br/>
         <Button
            marginTop="5"
            colorScheme="teal"
            variant="solid"
            type='submit'
            onClick={() => switchLights('essentialLights', "on")}
          >
            Essential Lights
          </Button>
      </Container>
      <Container w={["70%","50%"]} height="100px" border={"solid"}
        borderColor={"gray.200"}
        padding={5}
        margin={5}
        
        borderRadius={"md"}>
      <Button marginTop="25px" colorScheme="teal" onClick={()=>{handleAllLights()}}>Building Lights</Button>
      </Container>
      <Container w={["70%","50%"]} height="100px" border={"solid"}
        borderColor={"gray.200"}
        padding={5}
        margin={5}
        
        borderRadius={"md"}>
      <Button marginTop="25px" colorScheme="teal" onClick={()=>{handleExhibitLights()}}>Exhibit Power&Lights</Button>
      </Container>
     </Flex>
    </Flex>)
}
    </>
  );
};

export default LightsAndProjectors;
