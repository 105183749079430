import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useToast } from "@chakra-ui/react";
import { SERVER_URL } from "../constants";
import io from "socket.io-client";
import remove_duplicates from "../utils/remove_duplicates";
import Alert from "../utils/Alert";
import Request from "../utils/Request";

const SocketContext = createContext();

function SocketProvider({ children }) {
  const toast = useToast();
  const [socket, setSocket] = useState(false);
  const [alerts, setAlerts] = useState([]); // alerts need to be placed in the global context so that they are not lost
  const [requests, setRequests] = useState([]);
  const [newTicketcount,setnewTicketcount] = useState(0);
  const [newfacilityTicketcount,setnewfacilityTicketcount] = useState(0);

  const queueNewRequest = useCallback(
    (name, hostname, message, success, error) => {
      const newRequest = new Request(name, hostname, message, success, error);
      setRequests((curr_requests) =>
        remove_duplicates([newRequest, ...curr_requests])
      );
      toast({
        description: newRequest.description,
        status: newRequest.status,
        isClosable: true,
        position: "bottom-left",
      });
    },
    [toast]
  );

  const updateRequest = useCallback(
    (name, hostname, message, success, error, data) => {
      const newRequest = new Request(
        name,
        hostname,
        message,
        success,
        error,
        data
      );

      // change old version of the request to the new version.
      setRequests((curr_requests) =>
        curr_requests.map((r) => (r.equals(newRequest) ? newRequest : r))
      );
      toast({
        description: newRequest.description,
        status: newRequest.status,
        isClosable: true,
        position: "bottom-left",
      });
    },
    [toast]
  );

  function removeAlert(alert) {
    setAlerts((curr_alerts) =>
      curr_alerts.filter((this_alert) => !this_alert.equals(alert))
    );
  }

  function removeTrackingRequest(req) {
    setRequests((curr_requests) =>
      curr_requests.filter((this_req) => !this_req.equals(req))
    );
  }

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(SERVER_URL, {
          reconnection: true,
          reconnectionDelay: 500,
          query: { name: "frontend" },
        })
      );
    }
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", function () {
      console.log("connected check 2", socket.connected);
    });

    socket.on("reconnect", () => {
      console.log("reconnected", socket.connected);
    });

    // alerts
    socket.on("alerts-frontend", ({ hostname, name, alerts }) => {
      setAlerts((currentAlerts) => {
        const newAlerts = [];

        for (const alert of alerts) {
          newAlerts.push(new Alert(hostname, name, alert));
        }

        return remove_duplicates([...currentAlerts, ...newAlerts]);
      });
    });

    socket.on("missing-screens", ({ missingScreens }) => {
      setAlerts((currentAlerts) => {
        const newAlerts = missingScreens.map(
          (hostname) => new Alert(hostname, hostname, "missing")
        );
        return remove_duplicates([...currentAlerts, ...newAlerts]);
      });
    });

    socket.on("remove-alerts-frontend", ({ name, hostname, alerts }) => {
      // do this so we call setAlert only once
      setAlerts((currentAlerts) => {
        for (const alertName of alerts) {
          const alert = new Alert(hostname, name, alertName);
          currentAlerts = currentAlerts.filter((curr) => !curr.equals(alert));
        }
        return currentAlerts;
      });
    });
    // alerts end

    // start requests
    socket.on(
      "state-change-success-frontend",
      ({ success, name, hostname, message, error }) => {
        updateRequest(name, hostname, message, success, error, message);
      }
    );

    socket.on(
      "do-action-results-frontend",
      ({ error, message, success, name, hostname, data }) => {
        updateRequest(name, hostname, message, success, error, data);
      }
    );

    socket.on("newticket",(arg1)=>{
      console.log("newticket has been webserviced",arg1,newTicketcount);
      
      setnewTicketcount(newTicketcount+1);
    })
   
   socket.on("newfacilityticket",(arg1)=>{
      console.log("newfacilityticket has been webserviced",arg1,newfacilityTicketcount);
      
      setnewfacilityTicketcount(newTicketcount+1);
    })
    // end requests
    
    return () => {
      socket.off();
    };
  }, [socket, updateRequest,newTicketcount,newfacilityTicketcount]);

  // we put it in a provider so these data are available globally
  return (
    <SocketContext.Provider
      value={{
        socket,

        alerts,
        removeAlert,
        newTicketcount,
	newfacilityTicketcount,
        requests,
        queueNewRequest,
        removeTrackingRequest,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
const useSocket = () => useContext(SocketContext).socket;
const useAlerts = () => {
  const { alerts, removeAlert } = useContext(SocketContext);
  return { alerts, removeAlert };
};
const useTickets = ()=>{const {newTicketcount}= useContext(SocketContext);return newTicketcount}
const useFacilityTickets = () =>{ const {newfacilityTicketcount} = useContext(SocketContext);return newfacilityTicketcount}
const useProblematicScreens = () =>
  useContext(SocketContext).alerts.map((alt) => alt.title); // the title is the name of the screen
const useRequests = () => {
  const { requests, queueNewRequest, removeTrackingRequest } =
    useContext(SocketContext);
  return {
    requests,
    queueNewRequest,
    removeTrackingRequest,
  };
};
export {
  useSocket,
  SocketContext,
  useAlerts,
  useRequests,
  useProblematicScreens,
  useTickets,
  useFacilityTickets
};
export default SocketProvider;
