import {useState,useEffect} from 'react'
import axios from 'axios'
import { SERVER_URL } from '../../../constants';
import { columns } from '../Columns';
import ShowFacilityTicketsTable from '../ShowFacilityTicketsTable/ShowFacilityTicketsTable'
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react'
import {useAuth} from "../../../contexts/OAuthContext"
import { SocketContext } from '../../../contexts/SocketContext';
import { useContext } from 'react';


const ShowFacilityTickets=({setMenu})=>{

    const [isLoading,setIsLoading]= useState(false); 
    const [isAuthorized,setIsAuthorized]=useState(true);
    const [data,setData] = useState([])
    const navigate = useNavigate()
    const {Logout} = useAuth();
    // const {newTicketcount} = useTickets();
    // console.log(newTicketcount);
    const {newfacilityTicketcount} = useContext(SocketContext);
    console.log(newfacilityTicketcount);
    

   
    
    
    useEffect(()=>{
    setMenu(false);
    let mounted = true;
    async function getTicketsData(){
        try{
        setIsLoading(true);
       const result =  await axios.get(`${SERVER_URL}/api/v1/facilitytickets`,{withCredentials:true})
       console.log(result,result.data.tickets,'...hello',result.status);

       if(result && result.status!==401){
        setIsLoading(false);
        setData([...result.data.tickets]);
        console.log(result,result.data.tickets,'...hello',result.status);
        
       }
    }
       catch(err){
            console.log(err.response.status,err);
            if(err.response.status===401){
                setIsLoading(false);
                setIsAuthorized(false);
                
            }
            
       }
    }

    mounted && getTicketsData()
    return (()=>{
        mounted = false;
    })
    },[newfacilityTicketcount,setMenu])

    function handleBack(){
        navigate('/');
    }

    function handleLogout(){
        Logout();
    }
    

    return (
        <>
        {!isLoading?(!isAuthorized?(<><h2>you are not authorized to this route</h2><Button onClick={()=>handleBack()}>Back</Button></>):(<ShowFacilityTicketsTable DATA={data} COLUMNS= {columns} handleLogout={handleLogout}/>)):(<> <Spinner color='red.500' /></>)}
        </>
    )


}

export default ShowFacilityTickets