/*
Define all the fields that an exhibit will have to have and the actions that it can take

Note that validate() takes the current value of the field and returns an error message
    leave that string empty if there are no error message
*/

const exhibitActionFormFields = [
  {
    name: "name",
    validate: (value) => "",
    title: "Action Name",
    helperText: "Eg. Reboot",
  },
  {
    name: "URL",
    validate: (value) => "",
    title: "Action URL",
    helperText: "Eg. http://10.10.40.11:8000/reboot",
  },
  {
    name: "description",
    validate: (value) => "",
    title: "Action Description",
    helperText: "Eg. Reboots the exhibit",
  },
  {
    name: "type",
    validate: (value) => "",
    title: "Action Type",
    helperText: "Eg. 'Context Menu'|'Hidden'",
  },
  {
    name: "command",
    validate: (value) => "",
    title: "Action Command",
    helperText: "Eg. 'Dir'",
  },
];

const exhibitFormFields = [
  {
    name: "hostname",
    validate: (value) => "",
    title: "Exhibit Hostname",
    helperText: "Eg. DIS_CNTR_MM_02",
    disabled: true,
  },
  {
    name: "name",
    validate: (value) => "",
    title: "Exhibit Name",
    helperText: "Eg. Secrets of your Insides",
  },
  {
    name: "commonName",
    validate: (value) => "",
    title: "Exhibit Common Name",
    helperText: "Eg. The Robot",
  },
  {
    name: "numScreens",
    validate: (value) => "",
    title: "Number of Screens",
    helperText: "Eg. 1",
  },

  {
    name: "username",
    validate: (value) => "",
    title: "Exhibit Username",
    helperText: "Eg. DIS_CNTR_MM_02",
    disabled: true,
  },
  {
    name: "IPAddress",
    validate: (value) => "",
    title: "Exhibit IP Address",
    helperText: "Eg. 10.10.40.11",
    disabled: true,
  },
  {
    name: "number",
    validate: (value) => "",
    title: "Exhibit Number",
    helperText: "Eg. 212.2.1",
  },
  {
    name: "gallery",
    validate: (value) => "",
    title: "Exhibit's Gallery",
    helperText: "Eg. Health",
  },
  {
    name: "appName",
    validate: (value) => "",
    title: "Exhibit's App Name",
    helperText: "",
  },
  {
    name: "appPath",
    validate: (value) => "",
    title: "Exhibit's App Path",
    helperText: "",
  },
];

const exhibitThresholdFormFields = [
  {
    name: "thresholds.CPULoad",
    validate: (value) => "",
    title: "CPU Load % threshold",
    helperText: "Percentage",
  },
  // ==============================
  {
    name: "thresholds.RAMUsage",
    validate: (value) => "",
    title: "RAM usage % threshold",
    helperText: "Percentage",
  },
  // ==============================
  {
    name: "thresholds.temperature",
    validate: (value) => "",
    title: "CPU temperature threshold",
    helperText: "Degrees Celsius",
  },
];

export {
  exhibitFormFields,
  exhibitActionFormFields,
  exhibitThresholdFormFields,
};
