import {
  Box,
  Button,
  Flex,
  ListItem,
  UnorderedList,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  useDisclosure,
} from "@chakra-ui/react";
import DisplayAlerts from "../DisplayAlerts";
import DisplayRequests from "../DisplayRequests";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useAuth } from "../../contexts/OAuthContext";
import CheckAuth from "../../utils/CheckAuth";

const NavBarListItem = ({ to, title, onClose }) => {
  return (
    <Link to={to} onClick={onClose} >
      <ListItem
        _hover={{
          borderBottom: "1px solid white",
          borderTop: "1px solid white",
          paddingTop: 0,
          paddingBottom: 0,
        }}
        color="white"
        display={"inline"}
        float="left"
        bg="#333"
        padding={1.5}
        margin={1.5}
        transition="ease-in"
        transitionProperty={"all"}
        transitionDuration={"200ms"}
      >
        {title}
      </ListItem>
    </Link>
  );
};

const BurgerMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const {Logout} = useAuth();

  function handleLogout(){
    Logout()
  }

  return (
    <>
      <Flex
        w="90%"
        top={0}
        left={0}
        position={"fixed"}
        zIndex="10"
        justifyContent={"space-between"}
      >
        <Button
          onClick={onOpen}
          variant="solid"
          colorScheme={"teal"}
          padding={5}
          margin={3}
        >
          Menu
        </Button>
        <Flex>
          <Box margin={1}>
            <DisplayAlerts />
          </Box>
          <Box margin={1}>
            <DisplayRequests />
          </Box>
        </Flex>
      </Flex>
      {isOpen && (
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerContent overflowX={"hidden"}>
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody paddingLeft={0} bg="#333">
              <UnorderedList
                display={"flex"}
                flexDirection="column"
                bg="#333"
                w="100%"
              >
                <NavBarListItem to="/" title="Main Menu" onClose={onClose} />
                
                <CheckAuth><NavBarListItem to="/globalControls" title="Global Controls" onClose={onClose}/></CheckAuth>
                <CheckAuth><NavBarListItem
                  to="/lightsAndProjectors"
                  title="Lights and Projectors"
                  onClose={onClose}
                /></CheckAuth>
                <NavBarListItem to="/RaiseATicket" title="Submit Exhibit Fault" onClose={onClose}/>
                <CheckAuth><NavBarListItem to="/showtickets" title="Tickets-Exhibits" onClose={onClose}/></CheckAuth>
                <CheckAuth> <NavBarListItem to="/exhibits" title="ListofExhibits" onClose={onClose}/></CheckAuth>
		<CheckAuth> <NavBarListItem to='/charts' title="Statistics-Exhibits" onClose={onClose}/></CheckAuth>
		<NavBarListItem to="/RaiseAFacilityTicket" title="Submit Facility Fault" onClose={onClose}/>
                <CheckAuth><NavBarListItem to="/showfacilitytickets" title="Tickets Facilities" onClose={onClose}/></CheckAuth>
                <CheckAuth><NavBarListItem to="/facilities" title="List of Facilties" onClose={onClose}/></CheckAuth>

		<ListItem w="90%" display={"inline"} float={"right"}>
                  <DisplayRequests />
                </ListItem>
                <ListItem w="90%" display={"inline"} float={"right"}>
                  <DisplayAlerts />
                </ListItem>
              </UnorderedList>
            </DrawerBody>
            <DrawerFooter position={"absolute"} bottom={0} right={0}>
              <Button colorScheme={"red"} onClick={()=>{handleLogout()}}>
                Logout
              </Button>
              <Button colorScheme={"teal"} onClick={onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default BurgerMenu;
