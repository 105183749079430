import axios from "axios"
import { useState,useEffect} from "react"
import { SERVER_URL } from "../../constants"
import {Flex,FormControl,FormLabel,Input,Stack,Button, HStack} from "@chakra-ui/react"
import { Link, useParams } from "react-router-dom"

export default function EditFacility(){
    const [error,setError]=useState(false);
    const [message,setMessage]=useState('')
    const [facilityInfo,setFacilityInfo]=useState({

        buildingArea:'',
        buildingLevel:''
    })
    const [facilityInfoDuplicate,setFacilityInfoDuplicate]=useState({

        buildingArea:'',
        buildingLevel:''
    })
    const {id} = useParams();

    useEffect(()=>{
        let mounted = true;
        async function getData(){
        try{
        const result = await axios.get(`${SERVER_URL}/api/v1/facility/${id}`,{withCredentials:true})
        if(result){
                console.log(result.data);
                setFacilityInfo({
                    ...facilityInfo,
                    buildingArea:result.data.facility.buildingArea,
                    buildingLevel:result.data.facility.buildingLevel
                })

                setFacilityInfoDuplicate({
                    ...facilityInfoDuplicate,
                    buildingArea:result.data.facility.buildingArea,
                    buildingLevel:result.data.facility.buildingLevel
                })


        }
        }
        catch(err){
                setError(true)
                setMessage(err.message)
        }
    }
        mounted&&getData();

        return (()=>{
            mounted = false;
        })

        
},[]);


    function handleFacilityChange(e){
            setError(false);
            setFacilityInfo({
                ...facilityInfo,
                [e.target.name]:e.target.value
            })
    }

    async function editFacility(e){
        e.preventDefault();
        try{
            const isUpdated = await axios.put(`${SERVER_URL}/api/v1/updatefacility/${id}`,facilityInfo,{withCredentials:true});
            if(isUpdated){
                setMessage('Exhibit updated succesfully');
                
                

            }
        }
        catch(err){
            setError(!error);
            setMessage(err.message);
        }

    }

    function handleCancel(e){
        e.preventDefault();

        setFacilityInfo({
            ...facilityInfo,
            ...facilityInfoDuplicate
        })
    }

    


    return (
        <>
            <Flex width="100vw" height="100vh" justifyContent ="space-around" alignItems="center" direction="column"  bgColor="rgb(204, 204, 255)">
                <Flex direction="column" alignItems="center" justifyContent="center" bgColor="white" borderWidth={1} borderRadius={15} boxShadow="lg">
                    <Stack direction={["column","row"]} spacing="10px"margin="5px" >
                    <FormControl isRequired>
                                <FormLabel>BuildingLevel</FormLabel>
                                
                                    
                                    
                                    <Input type="text" name="buildingLevel" variant='outline' placeholder='Level 0' value={facilityInfo.buildingLevel}  onChange={(event)=>{handleFacilityChange(event)}}/>
                                
                               
                    </FormControl>
                        <FormControl isRequired>
                                <FormLabel>BuildingArea</FormLabel>
                                
                                    
                                    
                                    
                                    <Input type="text" name="buildingArea" variant='outline' placeholder='InnovationLab' value={facilityInfo.buildingArea}  onChange={(event)=>{handleFacilityChange(event)}}/>
                                
                            
                        </FormControl>
                    </Stack>
                    <HStack>
                    <Button onClick={(event)=>editFacility(event)} colorScheme="green">
                        Update
                    </Button>
                    <Button onClick={(event)=>handleCancel(event)}>Cancel</Button>
                    </HStack>


                </Flex>
                { (error||message)&&<h2>{message}</h2>}
                <Link to='/facilities'><Button>Back</Button></Link>
            </Flex>
        </>
    )
}