import { Grid, GridItem, Flex, Text, Switch, Select } from "@chakra-ui/react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from "@chakra-ui/react";

export const SwitchInput = ({ title, value, setValue, onChange }) => (
  <Grid templateColumns={"1fr 15%"}>
    <GridItem>
      <Text>{title}</Text>
    </GridItem>
    <GridItem>
      <Switch
        colorScheme="teal"
        size="lg"
        isChecked={value}
        onChange={() => {
          setValue((bool) => {
            onChange && onChange(!bool);
            return !bool;
          });
        }}
      />
    </GridItem>
  </Grid>
);

export const SelectInput = ({
  title,
  value,
  setValue,
  possibleValues,
  onChange,
}) => (
  <Grid templateColumns={"1fr 70%"}>
    <GridItem>
      <Text>{title}</Text>
    </GridItem>
    <GridItem>
      <Select
        placeholder={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange && onChange(e);
        }}
      >
        {possibleValues.map(
          (possibleValue, index) =>
            possibleValue !== value && (
              <option key={`${title}_${index}`}>{possibleValue}</option>
            )
        )}
      </Select>
    </GridItem>
  </Grid>
);

export const PercentageInput = ({ value, setValue }) => {
  return (
    <Flex>
      <NumberInput
        maxW="100px"
        mr="2rem"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Slider
        flex="1"
        focusThumbOnChange={false}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb fontSize="sm" boxSize="32px" children={value} />
      </Slider>
    </Flex>
  );
};
