import {Input} from '@chakra-ui/react'

export const GlobalFilter = ({filter,setFilter})=>{
   
    return (
        <span margin-left="20px">
            Search: {' '}
            <Input value= { filter || '' } onChange={(e)=>{ setFilter(e.target.value)} } width="500px"></Input>

        </span>
    )
}