let fullURL = window.location.href;
let URL=fullURL.split('//');
export const SERVER_URL = `${URL[0]}//${URL[1].split('/')[0]}`;
//export const SERVER_URL = "https://ems.dchfx.net";
// export const SERVER_URL = "http://10.10.40.6:6900";

export const ALL_LIGHTS_KEY = "allLights";
export const ALL_PROJECTORS_KEY = "allProjectors";

export const SCROLLBAR_STYLES = {
  "&::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "&::-webkit-scrollbar-track": {
    width: "6px",
    height: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "teal",
    borderRadius: "24px",
  },
};

export const SCREENSHOT_RERENDER_TIME = 10000;

export const POSSIBLE_STATES = [
  "Startup",
  "Shutdown",
  "Maintenance Mode",
  "None",
];

export const SCREEN_STATES = ["ON", "OFF", "SLEEPING"];

export const dummyExhbitJSON = (name) => ({
  name: name, //(Eg. Secrets of your Insides)
  commonName: "", // (Eg. The Robot)
  hostname: "", //(Eg. DIS_CNTR_MM_02)
  username: "", //(Eg. DIS_CNTR_MM_02)
  IPAddress: "", //(10.10.40.11)
  number: "", //(EG. 212.2.1)
  gallery: "", //(Eg. Health)
  appName: "",
  appPath: "",
  capabilities: [
    /*{
      actionName: "", //(Eg. Reboot)
      actionURL: "", //(Eg. http://10.10.40.11:8000/reboot)
      actionDescription: "", //(Eg. Reboots the exhibit)
      actionType: "", //“Context Menu”|”Hidden”
    },*/
  ],
  thresholds: {
    CPULoad: 60, // max values
    RAMUsage: 60, // max values
    temperature: 60, // max values
  },
  // for the state, I think it is better that we query rather than store it in the database...
  // state: {
  //   connectedToGlobalState: true, //true | false
  //   value: "", //"Startup" | "Shutdown" | "Maintenance Mode" | "None"
  //   sleeping: false, // true | false
  //   isOn: false, // true | false
  //   appPersistence: true, // true | false
  // },
});

// must be a function so that we don't just pass around a reference to the same JSON
export const dummyAction = () => ({
  actionName: "", //(Eg. Reboot)
  actionURL: "", //(Eg. http://10.10.40.11:8000/reboot)
  actionDescription: "", //(Eg. Reboots the exhibit)
  actionType: "", //“Context Menu”|”Hidden”
});
