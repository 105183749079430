import { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import axios from "axios";
// import SyncedScreenshot from "../SyncedScreenShot";
import { SERVER_URL } from "../../constants";
import { useRequests } from "../../contexts/SocketContext";

const Actions = ({ hostname, name }) => {
  const [actions, setActions] = useState([]);
  const { queueNewRequest } = useRequests();

  useEffect(() => {
    async function getActions() {
      try {
        const res = await axios.get(`${SERVER_URL}/screen/${hostname}/actions`);
        const filteredActions = res.data.actions.filter(function(action){return action.showInFront == "true"});
        setActions(filteredActions);
      } catch (error) {
        console.log("error fetching actions", { error });
      }
    }

    getActions();
  }, [hostname]);

  async function performAction(name, hostname, actionName) {
    const res = await axios.post(`${SERVER_URL}/screen/performAction`, {
      screenName: hostname,
      actionName,
    });
    // whichScreen, message, success, error
    queueNewRequest(
      name,
      hostname,
      res.data.message,
      res.data.success || null,
      res.data.error || null
    );
  }

  return (
    <>
      {actions.map((action) => (
        
        <Button
          key={`action_${action.name}_${hostname}`}
          onClick={() => performAction(name, hostname, action.name)}
        >
          {action.name}
        </Button>
      ))}
    </>
  );
};

export default Actions;
