import { Box, Wrap, WrapItem } from "@chakra-ui/react";

import ScreenshotCard from "./components/ScreenshotCard";
import { SCROLLBAR_STYLES, SERVER_URL } from "../../constants";
import { useState, useEffect } from "react";
import axios from "axios";
import SearchFilter from "./components/SearchFilter";
import sort_by_key from "../../utils/sort_by_key";
import { useProblematicScreens } from "../../contexts/SocketContext";
import { useAuth } from "../../contexts/OAuthContext";

const Screenshots = ({setMenu}) => {
  const [screens, setScreens] = useState([]);
  const [name, setName] = useState("");
  const [gallery, setGallery] = useState("");
  const problematicScreens = useProblematicScreens();
  const [isAuthorized,setIsAuthorized]=useState(true);
    const {makeAdmin}=useAuth();
//	const {user} = useUser();
  useEffect(() => {
    setMenu(true);
    async function getScreenIDs() {
      try {
        //const res = await axios.get(`${SERVER_URL}/screenIDs${createQuery()}`);
        const res = await axios.get(`${SERVER_URL}/screen/all`);
        setScreens(res.data.screens);

      } catch (error) {
        console.log("error fetching screens", { error });
//	console.log(user);
	if(error.response.status===401){
         
          setIsAuthorized(false);
           makeAdmin(false);    
      }
      }
    }
    getScreenIDs();
  }, [gallery, name,setMenu,makeAdmin]);

  return (
    <>
    {isAuthorized?
    (
    <Box
      w="100vw"
      h="90vh"
      overflowY={"scroll"}
      overflowX={"hidden"}
      css={SCROLLBAR_STYLES}
    >
      <Wrap h="100%">
        {sort_by_key(
          screens.map((screen) => ({
            ...screen,
            forSort: screen.name.toLowerCase(),
          })),
          "forSort"
        )
          .filter(({ name: t_name, gallery: t_gallery }) => {
            let res = true;
            if (name) {
              //res &&= t_name.startsWith(name);
                res &&= t_name.match(RegExp(name, 'gi'));
            }
            if (gallery) {
              res &&= t_gallery.startsWith(gallery);
            }
            return res;
          })
          .map(({ name, gallery, hostname }, index) => (
            <WrapItem key={`screen_${index}`}>
              <ScreenshotCard
                name={name}
                hostname={hostname}
                isProblematic={problematicScreens.includes(hostname)}
              />
            </WrapItem>
          ))}
      </Wrap>
      <SearchFilter
        name={name}
        gallery={gallery}
        setName={setName}
        setGallery={setGallery}
      />
    </Box>
  ):(<><h2>You are not authorized to view this page</h2></>)}</>)
};

export default Screenshots;
