function getDescription(alert) {
  switch (alert) {
    case "CPUTooHigh":
      return "too much CPU usage";
    case "temperatureTooHigh":
      return "CPU temperature too high";
    case "RAMPercentTooHigh":
      return "too much RAM usage";
    case "missing":
      return "Screen is missing";
    default:
      return alert;
  }
}

class Alert {
  constructor(hostname, title, alertName) {
    this.hostname = hostname;
    this.title = title;
    this.description = getDescription(alertName);
  }

  equals(other) {
    return (
      this.hostname === other.hostname &&
      // this.title === other.title &&
      this.description === other.description
    );
  }
}

export default Alert;
