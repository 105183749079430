import { SCREENSHOT_RERENDER_TIME, SERVER_URL } from "../../constants";
import { Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const SyncedScreenshot = ({ hostname, w, h }) => {
  const [reRender, setReRender] = useState(false);

  // we refetch the image several times...
  useEffect(() => {
    let t2;
    const t1 = setInterval(() => {
      setReRender(true);
      t2 = setTimeout(() => setReRender(false), 1);
    }, SCREENSHOT_RERENDER_TIME);

    return () => {
      clearInterval(t1);
      clearTimeout(t2);
    };
  }, []);

  if (!reRender)
    return (
      <Image
        w={w}
        h={h}
        padding={"5px"}
        objectFit='contain'
        src={`${SERVER_URL}/screenshots/${hostname}.jpg?t=${new Date().getTime()}`}
      />
    );
  else return null;
};

export default SyncedScreenshot;
