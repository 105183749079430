import { useState, useEffect } from "react";
import { Wrap, Box, Heading, Button } from "@chakra-ui/react";
import { SwitchInput, SelectInput } from "../../../../components/Input";
import { POSSIBLE_STATES, SERVER_URL } from "../../../../constants";
import { Link } from "react-router-dom";
import useChangeState from "../../../../hooks/useChangeState";
import axios from "axios";

const Gallery = ({ galleryName }) => {
  const { changeState, changeAppPersistence, changeSleep } = useChangeState();

  const [screens, setScreens] = useState([]);
  const [stateValue, setStateValue] = useState(POSSIBLE_STATES[0]);
  const [screenState, setScreenState] = useState(false);
  const [appPersistence, setAppPersistence] = useState(false);

  useEffect(() => {
    async function getGalleryStateDetails() {
      const res = await axios.get(
        `${SERVER_URL}/state/get/gallery?name=${galleryName}`
      );
      console.log({ state: res.data.state });
      setStateValue(res.data.state);
      setScreenState(res.data.sleep);
      setAppPersistence(res.data.appPersistence);
      setScreens(res.data.screens);
    }

    getGalleryStateDetails();
  }, [galleryName]);

  if (!galleryName) return null;

  return (
    <>
      <Heading>{galleryName}</Heading>
      <Heading size="sm">Screens:</Heading>
      <Wrap margin={2}>
        {screens.map((screen, index) => (
          <Link key={`gallery_screen_${index}`} to={`/screen/${screen}`}>
            <Button>{screen}</Button>
          </Link>
        ))}
      </Wrap>

      <SelectInput
        title={"Current Gallery State"}
        value={stateValue}
        setValue={setStateValue}
        possibleValues={POSSIBLE_STATES}
        onChange={(e) => changeState("gallery", galleryName, e.target.value)}
      />

      <SwitchInput
        title={"Current Sleep State"}
        value={screenState}
        setValue={setScreenState}
        onChange={(this_sleep) =>
          changeSleep("gallery", galleryName, this_sleep)
        }
      />
      <SwitchInput
        value={appPersistence}
        setValue={setAppPersistence}
        title={"App Persistence"}
        onChange={(this_appPersistence) =>
          changeAppPersistence("gallery", galleryName, this_appPersistence)
        }
      />

      <Link to={`/lightsAndProjectors`}>
        <Button margin={"1 0"} w="100%" colorScheme={"teal"}>
          Switch Lights
        </Button>
      </Link>
    </>
  );
};

export default Gallery;
