import { useState, useEffect, useRef } from "react";
import { useParams,  useNavigate } from "react-router-dom";
// import { VncScreen } from "react-vnc";
import { Button, Center,Flex } from "@chakra-ui/react";
// import axios from "axios";
import { SERVER_URL } from "../../constants";
import {saveAs} from "file-saver";


// const isValid = (vncUrl) => {
//   // if (!vncUrl.startsWith("ws://") && !vncUrl.startsWith("wss://")) {
//   //   return false;
//   // }

//   // return true;
//   return true;
// };

const VNCPage = () => {
  const { hostname } = useParams();
  // const [url, setURL] = useState("");
  // const [inputURL, setInputURL] = useState("");
  // const vncRef = useRef();
  const navigate = useNavigate();
  const [error,seterror] = useState(false)

  useEffect(() => {
    async function getURL() {
    try{
     fetch(`${SERVER_URL}/screen/VNC_URL/${hostname}`,{method:'GET',headers:{Accept:'application/vnc'}})
     .then(res=> {if(res.status===200){return res.blob();} else seterror(true)})
     .then((blob)=>{saveAs(blob,`${hostname}.vnc`)})
      
      
      // setURL(res.data.url);
      // const { connect, connected, disconnect } = vncRef.current ?? {};
    }
    catch(err){
      console.log(err);
    }}
    getURL();
  }, [hostname]);


  function handleBack(){
      navigate(`/screenDetails/${hostname}`);
  }

 return (
    <Flex width="100vw" height="100vh" align='center' justifyContent='center' alignItems='center'>
      <Center>
      {!error&&<h2>Please wait till you see a file download</h2>}
      {error && <h2>something went wrong,May be we don't have the vnc file on server for this exhibit</h2>}
      
             <Button onClick={()=>{handleBack()}} colorScheme={"teal"}>
             Back
             </Button>
      
      </Center>
    
    </Flex>
 )
  // return (
  //   <>
  //     <Grid
  //       w="40vw"
  //       templateColumns={"1fr 20% 20%"}
  //       position={"absolute"}
  //       left={"15vw"}
  //       top={2}
  //       padding={1}
  //       zIndex={100}
  //       border="1px solid teal"
  //       bg="white"
  //     >
  //       <GridItem>
  //         <Input
  //           value={inputURL}
  //           onChange={(e) => setInputURL(e.target.value)}
  //           placeholder="VNC URL with ws://"
  //         />
  //       </GridItem>
  //       <GridItem>
  //         <Button onClick={() => setURL(inputURL)} colorScheme={"gray"}>
  //           {validURL ? "Connected" : "Connect"}
  //         </Button>
  //       </GridItem>
  //       <GridItem>
  //         <Link to={`/screenDetails/${hostname}`}>
  //           <Button onClick={() => setURL(inputURL)} colorScheme={"teal"}>
  //             Back
  //           </Button>
  //         </Link>
  //       </GridItem>
  //     </Grid>
  //     <Center w="100vw" h="100vh">
  //       {validURL && (
  //         <VncScreen
  //           ref={vncRef}
  //           url={url}
  //           style={{
  //             width: "95vw",
  //             height: "95vh",
  //             border: "1px solid teal",
  //           }}
  //         />
  //       )}
  //     </Center>
  //   </>
  // );
};

export default VNCPage;
