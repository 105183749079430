import { Flex,Button, HStack } from "@chakra-ui/react"
import { Link,  useNavigate} from "react-router-dom"
import { useState,useEffect } from "react"
import axios from "axios"
import { SERVER_URL } from "../../constants"
import {
    Table,
    Thead,
    Tbody,
    Center,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'

export default function ShowFacilities(){

    const [facilities,setFacilities] = useState([]);
    const [error,setError]=useState(false);
    const [message,setMessage]=useState('')
    const [reload,setreload]=useState(false);
    const [isLoading,setIsLoading]=useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        let mounted=true;

        async function getFacilities(){

        try{
            setIsLoading(true);
            setError(false);
            setMessage('');
            const result = await axios.get(`${SERVER_URL}/api/v1/facilities`,{withCredentials:true})
            if(result){
                setIsLoading(false);
                console.log(result);
                setFacilities(result.data.facility);

            }else{
                setError(true);
            }
        }
        catch(err){
            if(err.response.status===401){
                
                setError(true);
                setMessage('You are not authorized to view this page');
            }
            else{
            
            setError(true);
            setMessage(err.message);
            }
        }
    }
    mounted&&getFacilities();

        return (()=>{
            mounted = false;
        })
        
    },[reload])


    async function handleDelete(id){
            try{
                const result = await axios.delete(`${SERVER_URL}/api/v1/facility/${id}`,{withCredentials:true})
                if(result){
                    
                    setMessage('Facility Deleted succesfully');
                    setreload(!reload);
                }

            }catch(err){
                setMessage('Unable to delete this facility,please try again')
            }
    }

    function handleNewFacility(e){
        e.preventDefault();
        navigate('/addFacility')
    }
    
        return (
            <>
           
            { (error||message)&&<h2>{message}</h2>}
            {isLoading?<h2>Loading data .......</h2>:
            (
            <>
            <Center margin="50px">
                <Button colorScheme="facebook" onClick={(event)=>{handleNewFacility(event)}}>Add New Facility</Button>
            </Center>
            <TableContainer marginTop="15px">
                <Table variant='simple'>
                    <TableCaption>List of Facilities</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>BuildingArea</Th>
                                <Th>BuildingLevel</Th>
                                <Th >Edit</Th>
                                <Th>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                        {facilities.map((facility) =>{
                            return (
                                <Tr>
                                    <Td>{facility.buildingArea}</Td>
                                    <Td>{facility.buildingLevel}</Td>
                                    <Td>
                                    <Link to={`/updateFacility/${facility._id}`}>
                                        <Button colorScheme='green'>Edit</Button>
                                     </Link>
                                    </Td>
                                    <Td>
                                    <Button colorScheme='red' onClick={()=>{handleDelete(facility._id)}}>Delete</Button>

                                    </Td>
                                </Tr>
                            )
                        })}
                        
                        </Tbody>
                        
                        
                    </Table>
                    </TableContainer>
                    </>
            )
}

            </>
        )
}