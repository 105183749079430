import {Input} from '@chakra-ui/react'

export const ColumnFilter = ({column})=>{
   const {filterValue ,setFilter} = column
    return (
        <span margin-left="20px">
            
            <Input value= { filterValue || '' } onChange={(e)=>{ setFilter(e.target.value || undefined ) } } width="0px"></Input>

        </span>
    )
}