import { useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../constants";

const useGalleries = () => {
  const [galleries, setGalleries] = useState([]);

  /*
  need to add the following:
  useSocket()
  socket.on("new-save", runUseEffectAgain)...
  */

  useEffect(() => {
    async function getGalleries() {
      console.log("fetching galleries");
      try {
        const res = await axios.get(`${SERVER_URL}/screen/galleries`);
        setGalleries(res.data.galleries);
      } catch (error) {
        console.log("fetching galleries", { error });
      }
    }
    getGalleries();
  }, []);

  return galleries;
};

export default useGalleries;
