import { Flex, Textarea, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useRequests } from "../../../../contexts/SocketContext";
import axios from "axios";
import { SERVER_URL } from "../../../../constants";

const CMDEditor = ({ name, hostname }) => {
  const { queueNewRequest } = useRequests();
  const [command, setCommand] = useState("");
  async function sendCommand() {
    console.log({ hostname, command });
    const res = await axios.post(`${SERVER_URL}/screen/runCommand`, {
      screenName: hostname,
      command,
    });
    queueNewRequest(
      name,
      hostname,
      res.data.message,
      res.data.success || null,
      res.data.error || null
    );
  }
  return (
    <Flex flexDirection={"column"}>
      <Textarea
        value={command}
        onChange={(e) => setCommand(e.target.value)}
        placeholder="CMD/ShellScript"
      />
      <Button colorScheme={"teal"} onClick={sendCommand}>
        Send To Screen
      </Button>
    </Flex>
  );
};

export default CMDEditor;
