import { useState, useEffect } from "react";
import axios from "axios";
import { SCROLLBAR_STYLES, SERVER_URL } from "../../../constants";
import {
  Grid,
  GridItem,
  Flex,
  Button,
  Heading,
  Input,
  Container,
  
  
} from "@chakra-ui/react";
import BrightnessSlider from "../BrightnessSlider";
import { SunIcon } from "@chakra-ui/icons";
import {toast} from 'react-toastify';
import useSwitchExhibitLights from "../../../hooks/useSwitchExhibitLights";

function debounce(func,delay){
console.log('I was debounced')
  let timeout;
  return function(){
  clearTimeout(timeout);
  timeout=setTimeout(()=>{func.apply(this, arguments)},delay)
  }
}


const notify = (toastMessage) => {
  
  toast.dark(<div>
    {toastMessage}
  </div>);

  
};



const LightsAndProjectorsRow = ({ id, name,floor,Command, switchFunc, brightFunc,show,ip,port}) =>{
     
    const [customizedname,setCustomizedName]=useState('');

    function handleChange(e){
        setCustomizedName(e.target.value)
    }

    async function handler(){
        console.log('i was called by debounce');
        try{
        const result = await axios.put(`${SERVER_URL}/api/v1/updatelight/${id}`,{customizedName:customizedname},{withCredentials:true});
        if(result){
            console.log('light name updated')
            notify(`The Name of this Light has been updated to ${customizedname}`);
        }
    }catch(err){
        console.log(err,'debounbce error');
        notify('error in updating light name');
    }
}

    function debounceHandler(e){
        console.log('called by onBlur',e.target.id);
        
        const wrapper = debounce(handler,500);
        wrapper();

    }

    useEffect(()=>{
        setCustomizedName(name)
    },[name])
    
    return (
  

  

  <Grid
    w="100%"
    templateColumns={["1fr 20% 20%","1fr 15% 15% 30%"]}
    gap={["2","0"]}
    paddingY={2}
    borderBottom={"1px solid"}
    borderBottomColor={"gray.200"}
  >
    <GridItem><Input type="text" id={id} name="customizedname" value={customizedname} onChange={(e)=>{handleChange(e)}} onBlur={e=>{debounceHandler(e)}}/> 
    </GridItem>
    <GridItem>
      <Button onClick={() => switchFunc(ip,Command,"on",port)}>on</Button>
    </GridItem>
    <GridItem>
      <Button onClick={() => switchFunc(ip,Command, "off",port)}>off</Button>
    </GridItem>
    <GridItem>
    <Button leftIcon={<SunIcon />} colorScheme='yellow' variant="solid" onClick={()=>{brightFunc()}}>
      
    </Button>
    {show&&<BrightnessSlider ip={ip} Command={Command} port={port} lighthook={useSwitchExhibitLights} />}
    
    </GridItem>
  </Grid>
);
     }


export default function LightsForExhibitPower(){
    const [lights, setLights] = useState([]);
    const [show,setShow]=useState(false);
   
    const switchLights = useSwitchExhibitLights();

    useEffect(() => {
        let mounted=true;
        let converted_data=[];
        async function getLightsData(){
        axios.get(`${SERVER_URL}/api/v1/lights`,{withCredentials:true}).then(({ data }) => {
            data.lights.forEach(ele=>{
                if(ele.floor && ele.lightType==='ExhibitPower-Floor'){
                  if(!Array.isArray(converted_data[ele.floor])){
                    converted_data[ele.floor]=[];
                    converted_data[ele.floor].push(ele)
                  }else{
                    converted_data[ele.floor].push(ele)
                  }
                }else if(ele.floor && ele.lightType==='ExhibitPower-Ceiling'){
                  if(!Array.isArray(converted_data[Number(ele.floor)+4])){
                    converted_data[Number(ele.floor)+4]=[];
                    converted_data[Number(ele.floor)+4].push(ele)
                  }else{
                    converted_data[Number(ele.floor)+4].push(ele)
                  }
                }
              })
          console.log(converted_data,'data');
          setLights([...converted_data]);
        });
    }
    mounted&&getLightsData();

    return ()=>{
        mounted=false;
    }
      }, []);


    function showBrightnessSlider(){
      setShow(!!!show);
    }

    

    

return (
      <Container
      w="100vw"
      h="100vh"
      maxWidth="100vw"
      
      padding={5}
      margin={5}
     
    >
      <Flex  wrap="wrap">
       {
       lights.map((level) => 
      <Flex direction="column" w={["90%","80%","80%","30%"]} h="600px"border={"solid"}
      borderColor={"black"}
      padding={5}
      margin={5}
      borderRadius={"md"} >
      <Heading>{`${level[0].lightType} Lights-${level[0].floor}`}</Heading>
      <Flex
        flexDirection= "column" 
        h="500px"
        overflowY={"scroll"}
        css={SCROLLBAR_STYLES}
      >
       
              {level.map(({ _id,defaultName,customizedName, Command,floor,IPAddress,port}, index)=>
                
                <LightsAndProjectorsRow
                key={`lights_${index}`}
                id={_id}
                name={customizedName}
                floor={floor}
                Command={Command}
                switchFunc={switchLights}
                brightFunc={showBrightnessSlider}
                show={show}
                ip={IPAddress}
                port={port}
                
                
              />
              )}
          
        
      </Flex>
      </Flex>
)}
       </Flex>
    </Container>
)
}

