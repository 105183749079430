import { useState ,useEffect} from "react";
import axios from 'axios';
import { SERVER_URL } from "../../constants";

import { Stack,Button,Flex,Input,InputGroup,Select,Textarea, Box, Center, InputLeftElement,FormControl,
    FormLabel, 
    VStack} from "@chakra-ui/react";
// import { Formik,Form } from "formik";
import {EmailIcon, CheckCircleIcon} from "@chakra-ui/icons";
import {toast} from 'react-toastify';





function RaiseATicket(){
    const [ticketDetails,setTicketDetails]=useState({
        user_emailaddress:'',
        exhibitName:'',
        title:'',
        Description:'',
        problemImages:[]
    }) 
    const [allGalleries,setallGalleries]=useState([]);
    const [galleries,setGalleries] = useState([]);
    const [status,setStatus]=useState({
        isLoading:false,
        isError:false,
        isSuccess:false
    })
    const [chosenGallery,setChosenGallery] = useState('');
    const [exhibits,setExhibits] = useState([]);
   
    useEffect(()=>{
        let mounted=true;
        
        async  function getExhibitsandGalleries(){
            try{
            const result = await axios.get(`${SERVER_URL}/api/v1/allExhibits`)
            if(result){
                let screenGalleries=[]
		let screenGalleryUnique = []
                console.log(result.data,typeof(result.data));
                result.data.exhibit.forEach((screen)=>{
                        screenGalleries.push(screen); 
                        screenGalleryUnique.push(screen.gallery);
                        
                })
                setGalleries([...new Set(screenGalleryUnique)]);
                setExhibits([...screenGalleries]);
		setallGalleries([...screenGalleries]);
		setTicketDetails({
                    ...ticketDetails,
                    user_emailaddress:result.data.user
                })

                
            }
        }
        catch(err){
                console.log('error in fetching galleries for raise a ticket',err)
        }
    }
    mounted && getExhibitsandGalleries()
    
        return (()=>{
            mounted=false;
        })
    },[])

    function handleTicketDetails(event){
        setStatus({
            ...status,
            isError:'',
            isSuccess:''
        })
        
        setTicketDetails({
            ...ticketDetails,
            [event.target.name]:event.target.value
        })
        
    }

    function handleGallery(event){
            
            setChosenGallery(
                event.target.value
            )
            if(event.target.value){
                let filtered = allGalleries.filter(gallery=>{return gallery.gallery=== event.target.value});
                
            setExhibits([...filtered])
            }
            else{
                setExhibits([...allGalleries]);
            }
    }

   
   const notify = (toastMessage) => {
  
        toast.dark(<div>
          {toastMessage}
        </div>);
      
        
      };   

    

    async function SubmitTicketDetails(e){


        try{
        e.preventDefault();
        setStatus({...status,isLoading:true})
        
        const ticketSubmitted = await axios.post(`${SERVER_URL}/api/v1/addticket`,ticketDetails,{withCredentials:true})
        if(ticketSubmitted){
            setStatus({...status,isLoading:false})
            
            setStatus({...status,isSuccess:ticketSubmitted.data.message})
	    notify('Ticket created succesfully!!!');
           

            setTicketDetails({
                ...ticketDetails,
                exhibitName:'',
                title:'',
                Description:''
            })
           
            
        }
        else{
            
            setStatus({isLoading:false})
            console.log('ese erri',ticketSubmitted.message)
            setStatus({
                ...status,
                isError:ticketSubmitted.message
            })
            

        }

    }catch(err){
        console.log('error',err);
        notify('Something went wrong,please try again');
	setStatus({...status,isLoading:false})
        setStatus({...status,isError:err.message})
    }
}
function ImagetoBase64(event){
    console.log(event.target.files,typeof(event.target.files));
    
        // const preview = document.querySelector('#preview');
        // const files = document.querySelector('input[type=file]').files;
        
      
        function readAndPreview(file) {
          // Make sure `file.name` matches our extensions criteria
          console.log(file);
          if (/\.(jpe?g|png)$/i.test(file.name)) {
            console.log('im crct format');
            const reader = new FileReader();
      
            reader.addEventListener("load", () => {
           
               console.log(reader.result);
               let img = reader.result;
               ticketDetails.problemImages.push(img);
              
            }, false);
      
            reader.readAsDataURL(file);
            
            

          }
        }

        for( const i  in event.target.files){
            // console.log(event.target.files[i],event.target.files);
            readAndPreview(event.target.files[i]);
        }
        
        
      
      
}



    return (
        
        <Flex width="100vw" height="100vh" justifyContent ="center" alignItems="center" flexdirection="column"  bgColor="rgb(0, 82, 204)">
            
                
                
                <form onSubmit={SubmitTicketDetails}>
                <Flex direction="column" alignItems="center"  justifyContent="center" width={["350px","550px","800px","1200px"]}
height={["1200px","800px"]}  bgColor="white" borderWidth={1} borderRadius={15} boxShadow="lg" gap={["5px","25px"]}>
                    <VStack width={["75%","90%"]}  spacing="10px">
                    
                        <FormControl isRequired>
                            <FormLabel>Your Email</FormLabel>
                            <InputGroup>
                                
                                <InputLeftElement pointerEvents="none" children= {<EmailIcon color='black.100'/>} />
                                
                                <Input FullWidth type="text" name="user_emailaddress" variant='outline' placeholder='xyz@thediscoverycentre.ca' value={ticketDetails.user_emailaddress}  onChange={(event)=>{handleTicketDetails(event)}} isReadOnly="true"/>
                            
                            </InputGroup>
                        </FormControl>
                            <FormControl isRequired >
                            
                            <FormLabel>Exhibit Fault</FormLabel>
                            <Input FullWidth type="text" name="title" variant='outline' placeholder='Ticket Title' value={ticketDetails.title} onChange={handleTicketDetails}/>
                            
                            </FormControl>
                            <FormControl >
                                <FormLabel>Gallery</FormLabel>
                                <Select FullWidth name="gallery" placeholder="gallery" value={chosenGallery} onChange={handleGallery}>
                                        {galleries.map(screen=>{
                                            return <option key={`${screen}gallery`}>{screen}</option>
                                        })}
                                </Select>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Exhibit Name</FormLabel>
                                <Select FullWidth placeholder="select Exhibit" name="exhibitName" value ={ticketDetails.exhibitName} onChange={handleTicketDetails}>
                                {exhibits.map(screen=>{
                                            return <option key={`${screen.name}gallery`}>{screen.name}</option>
                                        })}

                                </Select>
                            </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Fault Details</FormLabel>
                            <Textarea size="md" height={["100px","150px"]} placeholder='enter the details of the fault' name='Description' value={ticketDetails.Description} onChange={handleTicketDetails}>

                            </Textarea>
                        </FormControl>

                        <Input type="file" name="solutionImages" variant="unstyled" multiple  onChange={ImagetoBase64} placeholder='Upload Picture'/>
                        <span>Only Png,jpeg,jpg image formats are allowed</span>

                        <Button width="80%" colorScheme="teal"  textColor= "white" variant="solid" rightIcon={<CheckCircleIcon color='black'/>} type="submit" isLoading={status.isLoading}>
                                    Submit the Fault
                        </Button>
                    </VStack>


                            
                    
                            

                            {status.isLoading && <h3>Please wait, we are submitting your ticket.......</h3>}
                            {status.isSuccess && <h3>{status.isSuccess}</h3>}
                            {status.isError && <h3>{status.isError}</h3>}
                </Flex>
                </form>
               
            
        </Flex>
        
    )
   


}


export default RaiseATicket;
