import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
// css for the carousel/screenshots
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ToastContainer, toast,Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
// 1. Import the extendTheme function
import { ChakraProvider, Grid, GridItem } from "@chakra-ui/react";
import BurgerMenu from "./components/BurgerMenu";
import ScreenDetails from "./pages/ScreenDetails";
import Screenshots from "./pages/Screenshots";
import ScreenRegistration from "./pages/ScreenRegistration";
import GlobalControlsPage from "./pages/GlobalControlsPage";
import SocketProvider from "./contexts/SocketContext";
import VNCPage from "./pages/VNCPage";
import LightsAndProjectors from "./pages/LightsAndProjectors";
import RaiseATicket from "./pages/RaiseATicket/RaiseATicket";
import Login from "./pages/Auth/Login";
import CheckAuth from "./utils/CheckAuth";
import OAuthProvider from "./contexts/OAuthContext";
import CheckLogin from "./utils/CheckLogin";
import MainTickets from "./pages/AdminDashboard/MainTicket/MainTickets";
import ShowTickets from "./pages/AdminDashboard/ShowTickets/ShowTickets";
import ShowExhibits from "./pages/ExhibitandGalleries/ShowExhibits";
import EditExhibit from "./pages/ExhibitandGalleries/EditExhibit";
import AddExhibit from "./pages/ExhibitandGalleries/AddExhibit";
import ExhibitCharts from "./pages/Charts/ExhibitCharts";
import LightsByLevel from "./pages/LightsAndProjectors/LightsByLevel/newindex";
import AddFacility from "./pages/Facilities/AddFacility";
import EditFacility from "./pages/Facilities/EditFacility";
import ShowFacilities from "./pages/Facilities/ShowFacilities";
import RaiseAFacilityTicket from "./pages/RaiseAFacilityTicket/RaiseAFacilityTicket";
import ShowFacilityTickets from "./pages/FacilityAdminDashboard/ShowFacilityTickets/ShowFacilityTickets";
import MainFacilityTickets from "./pages/FacilityAdminDashboard/MainFacilityTickets/MainFacilityTickets";
import LightsByFloorPlan1 from "./pages/LightsAndProjectors/LightsByFloorPlan/LightsByFloorPlan1";
import LightsForExhibitPower from "./pages/LightsAndProjectors/LightsForExhibitPower";

// 2. Extend the theme to include custom colors, fonts, etc
// const colors = {
//   brand: {
//     900: "#1a365d",
//     800: "#153e75",
//     700: "#2a69ac",
//   },
// };

// const theme = extendTheme({ colors });

// 3. Pass the `theme` prop to the `ChakraProvider`
function App() {
  const [showMenu,setShowMenu]=useState(true);
 
  return (
    <ChakraProvider>
      <SocketProvider>
        <OAuthProvider>
        <Router>
          {showMenu && <BurgerMenu />}
	   <ToastContainer
          position="bottom-center"
          autoClose={5000}
          transition={Slide}
          toastStyle={{ borderRadius: '10px', color: 'white' }}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={2}
          
        />
          <Grid w="100vw" h="92vh" templateRows={"1fr"}>
            <GridItem>
              <Routes>
                <Route path="/" element={<CheckAuth><Screenshots setMenu={setShowMenu} /></CheckAuth>} />
                <Route
                  path="/screenDetails/:hostname"
                  element={<ScreenDetails />}
                />
                <Route
                  path="/registerScreen/:hostname"
                  element={<ScreenRegistration />}
                />
                <Route path="/VNC/:hostname" element={<VNCPage />} />
                <Route
                  path="/globalControls"
                  element={<GlobalControlsPage />}
                />
                <Route
                  path="/lightsAndProjectors"
                  element={<LightsAndProjectors />}
                />
                 <Route
                  path="/AllLights"
                  element={<LightsByLevel />}
                />
		<Route path="/RaiseATicket" element={<CheckAuth><RaiseATicket/></CheckAuth>}/>
                <Route path = "/login" element={<CheckLogin><Login setMenu={setShowMenu}/></CheckLogin>}></Route>
                <Route path='/showtickets' element={<CheckAuth><ShowTickets setMenu={setShowMenu}/></CheckAuth>}></Route>
                <Route path="/ticket/:id" element={<CheckAuth><MainTickets setMenu={setShowMenu}/></CheckAuth>}/>
                <Route path='/exhibits' element={<CheckAuth><ShowExhibits/></CheckAuth>}></Route>
                <Route path='/update/:id' element={<CheckAuth><EditExhibit/></CheckAuth>}></Route>
                <Route path='/addExhibit' element={<CheckAuth><AddExhibit></AddExhibit></CheckAuth>}></Route>
		<Route path='/charts' element={<CheckAuth><ExhibitCharts/></CheckAuth>}></Route>
		<Route path='/facilities' element={<CheckAuth><ShowFacilities/></CheckAuth>}></Route>
                <Route path='/updateFacility/:id' element={<CheckAuth><EditFacility/></CheckAuth>}></Route>
                <Route path='/addFacility' element={<CheckAuth><AddFacility></AddFacility></CheckAuth>}></Route>
                <Route path="/RaiseAFacilityTicket" element={<CheckAuth><RaiseAFacilityTicket/></CheckAuth>}/>
                <Route path='/showfacilitytickets' element={<CheckAuth><ShowFacilityTickets setMenu={setShowMenu}/></CheckAuth>}></Route>
                <Route path="/facilityticket/:id" element={<CheckAuth><MainFacilityTickets setMenu={setShowMenu}/></CheckAuth>}/>
		<Route path='/floor1' element={<LightsByFloorPlan1></LightsByFloorPlan1>}></Route>
                <Route path='/exhibitpowerlights' element={<LightsForExhibitPower/>}/>


              </Routes>
            </GridItem>
          </Grid>
        </Router>
        </OAuthProvider>
      </SocketProvider>
    </ChakraProvider>
  );
}

export default App;
