import { useState } from "react";
import ScreenRegisterForm from "./components/ScreenRegisterForm";
import ScreenRegMenu from "./components/ScreenRegMenu";

function ScreenRegistration() {
  const [showDetails, setShowDetails] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  if (showDetails && initialValues) {
    return (
      <ScreenRegisterForm
        initialValues={initialValues}
        hostname={initialValues.hostname}
        setShowDetails={setShowDetails}
      />
    );
  } else {
    return (
      <ScreenRegMenu
        setShowDetails={setShowDetails}
        setInitialValues={setInitialValues}
      />
    );
  }
}

export default ScreenRegistration;
