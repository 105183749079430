import { useEffect, useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import { useSocket } from "../../../../contexts/SocketContext";

const AlertBasedOnPerformance = ({
  processName,
  processCommand,
  forWhat,
  usage,
  maxThreshold,
  minThreshold,
}) => {
  if (usage === -1) {
    return (
      <Alert status="error">
        <AlertIcon />
        {processName ? <AlertTitle>{processName}</AlertTitle> : null}
        {/* {processCommand ? (
          <AlertDescription>{processCommand}</AlertDescription>
        ) : null} */}
        {forWhat}: no information
      </Alert>
    );
  }

  if (usage > maxThreshold)
    return (
      <Alert status="error">
        <AlertIcon />
        {processName ? <AlertTitle>{processName}</AlertTitle> : null}
        {/* {processCommand ? (
          <AlertDescription>{processCommand}</AlertDescription>
        ) : null} */}
        {forWhat}: {usage} %
      </Alert>
    );

  if (usage < minThreshold) {
    return (
      <Alert status="success">
        <AlertIcon />
        {processName ? <AlertTitle>{processName}</AlertTitle> : null}
        {/* {processCommand ? (
          <AlertDescription>{processCommand}</AlertDescription>
        ) : null} */}
        {forWhat}: {usage} %
      </Alert>
    );
  }

  return (
    <Alert status="warning">
      <AlertIcon />
      {processName ? <AlertTitle>{processName}</AlertTitle> : null}
      {/* {processCommand ? (
        <AlertDescription>{processCommand}</AlertDescription>
      ) : null} */}
      {forWhat}: {usage} %
    </Alert>
  );
};

const Monitor = ({ hostname }) => {
  const socket = useSocket();
  const toast = useToast();
  const [details, setDetails] = useState({});

  useEffect(() => {
    // on render send to monitor details
    socket.emit("set-up-monitor-details", { hostname });
    return () => {
      // disconnect
      socket.emit("clear-monitor-details", { hostname });
    };
  }, [hostname, toast, socket]);

  useEffect(() => {
    socket.on(
      "set-up-monitor-results",
      ({ /*hostname,*/ message, success }) => {
        toast({
          description: `setting up live details: ${message}`,
          status: success ? "success" : "error",
          isClosable: true,
          position: "bottom-left",
        });
      }
    );

    socket.on("clear-monitor-results", ({ /*hostname,*/ message, success }) => {
      toast({
        description: `clearing up live details: ${message}`,
        status: success ? "success" : "error",
        isClosable: true,
        position: "bottom-left",
      });
    });

    socket.on("details-frontend", ({ details }) => {
      setDetails(details);
    });
    return () => {
      socket.off("set-up-monitor-results");
      socket.off("clear-monitor-results");
      socket.off("details-frontend");
    };
  }, [hostname, toast, socket]);

  return (
    <Tabs
      w="90%"
      h="90%"
      border={"solid"}
      borderColor={"gray.200"}
      padding={5}
      margin={5}
      borderRadius={"md"}
    >
      <TabList>
        <Tab>Monitor</Tab>
        <Tab>Processes by memory</Tab>
        <Tab>Processes by CPU</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Center>
            <Box w="100%">
              <AlertBasedOnPerformance
                forWhat={"CPU usage total"}
                usage={details.CPUTotal || -1}
                maxThreshold={50}
                minThreshold={20}
              />
              <AlertBasedOnPerformance
                forWhat={"CPU usage - max load on one"}
                usage={details.CPUMaxOnOne || -1}
                maxThreshold={50}
                minThreshold={20}
              />
              <AlertBasedOnPerformance
                forWhat={"Memory usage"}
                usage={details.RAMUsagePercent || -1}
                maxThreshold={50}
                minThreshold={20}
              />
              <AlertBasedOnPerformance
                forWhat={"CPU temperature main"}
                usage={details.CPUTemperaturesMain || -1}
                maxThreshold={50}
                minThreshold={20}
              />
              <AlertBasedOnPerformance
                forWhat={"CPU temperature max"}
                usage={details.CPUTemperaturesMain || -1}
                maxThreshold={50}
                minThreshold={20}
              />
            </Box>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Box w="100%">
              <Processes
                processes={details.mostConsumingProcessesByMem || []}
                forCPU={false}
              />
            </Box>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Box w="100%">
              <Processes
                processes={details.mostConsumingProcessesByCPU || []}
                forCPU={true}
              />
            </Box>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const Processes = ({ processes, forCPU }) => {
  return (
    <>
      {processes.map(({ name, cpu, mem, command }, index) => (
        <AlertBasedOnPerformance
          key={`processes_${index}`}
          processName={name}
          processCommand={command}
          forWhat={forCPU ? "CPU" : "RAM"}
          usage={forCPU ? cpu : mem}
          maxThreshold={50}
          minThreshold={10}
        />
      ))}
    </>
  );
};

export default Monitor;
