import React  from 'react'
import {

    Button,
    
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from "@chakra-ui/react";

function Popup({onShowpopup ,onClosepopup,deleteScreen}){
    console.log('Im called ',onClosepopup);
    // alert('Im called from outside' );
    return(
        
        
    <AlertDialog isOpen = {onShowpopup} onClose = {onClosepopup}>
     <AlertDialogOverlay>
       <AlertDialogContent>
         <AlertDialogHeader fontSize='lg' fontWeight='bold'>
           Delete Screen
         </AlertDialogHeader>

         <AlertDialogBody>
           Are you sure? You can't undo this action afterwards.
         </AlertDialogBody>

         <AlertDialogFooter>
           <Button  onClick={onClosepopup}>
             Cancel
           </Button>
           <Button colorScheme='red' onClick={deleteScreen} ml={3}>
             Delete
           </Button>
         </AlertDialogFooter>
       </AlertDialogContent>
     </AlertDialogOverlay>
   </AlertDialog>
    )
}

export default Popup

