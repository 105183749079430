
import{Stack,Button,Flex,Input,InputGroup,Select,Textarea,InputLeftElement,FormControl,FormLabel} from "@chakra-ui/react"
import {useState,useEffect} from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom'
import { SERVER_URL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete";
import {ArrowBackIcon,CheckCircleIcon,CheckIcon} from "@chakra-ui/icons"


function MainFacilityTickets({setMenu}){

    const {id} = useParams();
    const navigate = useNavigate()
    const [ticketInfo,setTicketInfo] =useState({

        status:'',
        Description:'',
        Solution:'',
        assignedTo:[],
        buildingArea:'',
        problemImages:[],
        solutionImages:[]

    })
    const [users,setUsers] =useState([])
    const [userOpts,setUserOpts] = useState([]);
    const [selectedusers,setSelectedusers]=useState([]);
    const [message,setMessage]=useState({
        isLoading:'',
        isSuccess:'',
        isError:''
    });
    const [isClosed,setIsClosed]=useState(false);
    const [refresh,setRefresh] = useState(false);

    useEffect(()=>{
        setMenu(false);
        let mounted = true;
        async function getTicketData(){
            try{
            const result =  await axios.get(`${SERVER_URL}/api/v1/facilityticket/${id}`)
            if(result){
                    console.log(result.data,result.data.ticket);
                    setTicketInfo({
                        status:result.data.ticket.status,
                        Description:result.data.ticket.Description,
                        Solution:result.data.ticket.Solution,
                        assignedTo:[...result.data.ticket.assignedTo],
                        problemImages:result.data.ticket.problemImages?result.data.ticket.problemImages:[],
                        solutionImages:result.data.ticket.solutionImages?result.data.ticket.solutionImages:[],
                        buildingArea:result.data.ticket.buildingArea

                    })
                    if(result.data.ticket.assignedTo.length>0){
                           let  usersSelect=[];
                            result.data.ticket.assignedTo.forEach((user)=>{
                                    usersSelect.push(
                                        {label:user,value:user}
                                    )
                                

                            })
                            
                            setSelectedusers([...usersSelect]);
                    }
                    result.data.ticket.status==='Closed'&&setIsClosed(true);
            }
        }
        catch(err){
            console.log(err)
        }

        }
        async function getUsers(){
            try{
                    const result = await axios.get(`${SERVER_URL}/api/v1/users`)
                    if(result){
                        const adminUsers=[];
                        const adminUserOpts=[];
                        console.log(result,'users');
                        result.data.users.forEach((user)=>{
                            if(user.role==='admin'){
                                adminUsers.push(user);
                                console.log(ticketInfo.assignedTo,'kdjfhkds');
                                adminUserOpts.push({label:user.name,value:user.name,email:user.email,id:user._id})
                            }
                        })
                        console.log('users',adminUsers,adminUserOpts);
                        
                        setUsers([...adminUsers])
                        setUserOpts([...adminUserOpts]);
                    }
            }
            catch(err){
                        
                        console.log('err in users',err)
            }
        }

        if(mounted){
            getTicketData();
            getUsers();
        }
        return ()=>{
            mounted=false;
        }
    },[id,refresh]);

    async function handleTicketInfoDetails(event){
        setMessage({
            ...message,
            isError:'',
            isSuccess:''
        })
        
        setTicketInfo({
            ...ticketInfo,
            [event.target.name]:event.target.value
        })


    }

    

    function handleTicket(value){
       
        setMessage({
            ...message,
            isError:'',
            isSuccess:''
        })
            setTicketInfo({
                ...ticketInfo,
                assignedTo:[...value]
            })
           
           setSelectedusers([...value]);
    }

    function handleTicketIn(event){
        
         setMessage({
            ...message,
            isError:'',
            isSuccess:''
        })
        
            setTicketInfo({
                ...ticketInfo,
                assignedTo:[event.target.value]
            })
           
           
    }

    async function AssignTicket(){
        try{
            setMessage({...message,isLoading:'your ticket is being Assigned,please wait ......'})
            const assigned = await axios.put(`${SERVER_URL}/api/v1/assignfacilityticket/${id}`,{assignees:[...ticketInfo.assignedTo]});
            if(assigned){
                setMessage({...message,isLoading:'',isSuccess:'your ticket has been assigned succesfully '});
                setRefresh(!!!refresh);
                console.log(assigned.data);

            }else{
                console.log('error');
                setMessage({...message,isLoading:'',isError:'Looks like some issue in assigning this ticket,please try again after sometime'})
            }
        }catch(err){
            console.log(err,'error');
            setMessage({...message,isLoading:'',isError:'Looks like some issue in assigning this ticket,please try again after sometime'})
   
        }
    }
    async function saveTicket(){
        try{
        if(((ticketInfo.status=== 'Closed'&&ticketInfo.Solution)|| ticketInfo.status===  'Waiting' || ticketInfo.status==='Assigned' )){
            console.log('ticket',ticketInfo);
        setMessage({...message,isLoading:`your ticket is updating to the status: ${ticketInfo.status},please wait ......`})

        const saved  = await axios.put(`${SERVER_URL}/api/v1/updatefacilityticket/${id}`,ticketInfo) 
        console.log(saved);
        if(saved){
            setMessage({...message,isLoading:'',isSuccess:`your ticket has been ${ticketInfo.status} succesfully  `});
            setRefresh(!!!refresh);
            console.log(saved.data);


                }        
            }
            else{

                
                    setMessage({...message,isError:'To close the ticket please chose correct status and please check if u have entered the solution'});
   
                }
    }catch(err){
        console.log(err);
        setMessage({...message,isError:'something went  wrong,please try again'});
    }
    }

    function Back(){
            navigate('/showfacilitytickets');
    }

    async function AdditionalNotes(){
        try{
        const resaved  = await axios.put(`${SERVER_URL}/api/v1/resavefacilityticket/${id}`,ticketInfo) 
        console.log(resaved);
        if(resaved){
            setMessage({...message,isLoading:'',isSuccess:`your additional notes have been added sucessfully `});
            console.log(resaved.data);


                }        
            
            else{

                
                    setMessage({...message,isError:'Unable to add additional notes,please try again after sometime'});
   
                }
        }
        catch(err){
            console.log(err);
            setMessage({...message,isError:'something went  wrong,please try again'});
        }
    }

    function ImagetoBase64(event){
        console.log(event.target.files,typeof(event.target.files));
        
            // const preview = document.querySelector('#preview');
            // const files = document.querySelector('input[type=file]').files;
            
          
            function readAndPreview(file) {
              // Make sure `file.name` matches our extensions criteria
              console.log(file);
              if (/\.(jpe?g|png)$/i.test(file.name)) {
                console.log('im crct format');
                const reader = new FileReader();
          
                reader.addEventListener("load", () => {
                //   const image = new Image();
                //   image.height = 100;
                //   image.title = file.name;
                   console.log(reader.result);
                   let img = reader.result;
                   ticketInfo.solutionImages.push(img);
                  
                }, false);
          
                reader.readAsDataURL(file);
                
                

              }
            }

            for( const i  in event.target.files){
                // console.log(event.target.files[i],event.target.files);
                readAndPreview(event.target.files[i]);
            }
            
            
          
          
    }

    return(
        <>
        <Flex direction="column" justify='space-evenly'>
            
            <Flex direction={["column","row"]} justify="space-evenly" align="center" height="100px" bgColor="rgb(204, 204, 255)" width="100vw" >
                <Button leftIcon={<ArrowBackIcon/>} onClick={()=>{Back()}}>Back</Button>
                <Button leftIcon={<CheckCircleIcon/>} onClick={()=>{AdditionalNotes()}} colorScheme="green">AdditionalNotes</Button>
                <Button  rightIcon={<CheckIcon/>} isDisabled={ticketInfo.status==='Closed'} colorScheme="red" onClick={()=>{AssignTicket()}} marginLeft={["0px","100px"]}> Assign</Button>
                <Button rightIcon={<CheckCircleIcon/>} isDisabled={isClosed} color="green" onClick={()=>{saveTicket()}}>Save</Button>
            </Flex>
            {message.isLoading&&<h2>{message.isLoading}</h2>}
            {message.isError&&<h2>{message.isError}</h2>}
            {message.isSuccess&&<h2>{message.isSuccess}</h2>}
            <Flex width="100vw" direction={["column","row"]} justifyContent='space-evenly' alignContent="center">
                <Stack direction="column" justify='space-around' align='center' columnGap="20px">
                    <FormControl isReadOnly>
                        <FormLabel>Description</FormLabel>
                        <Textarea width={["150px","350px","500px"]} height={["100px","250px"]} placeholder='Issue of the ticket' name='Description' onChange={handleTicketInfoDetails}value={ticketInfo.Description} >

                        </Textarea>
                            
                    </FormControl>

                    <FormControl isRequired >
                        <FormLabel>Solution</FormLabel>
                        <Textarea width={["200px","350px","500px"]} height={["150px","350px"]} placeholder='enter the solution' name='Solution' onChange={handleTicketInfoDetails} value={ticketInfo.Solution}>

                        </Textarea>
                            
                    </FormControl>
                    <FormControl>
                        <FormLabel>Would u like to upload an image?You can do it here and Only Png,Jpg or Jpeg formats are allowed!!!!</FormLabel>
                        <Input type="file" name="solutionImages" variant="unstyled" multiple  onChange={ImagetoBase64}/>

                        
                    
                    </FormControl>
                    {ticketInfo.solutionImages.length>0 && <a href={ticketInfo.solutionImages[0]} download ><img width="50" height="50" src={ticketInfo.solutionImages[0]} alt='unable to load it' /> click here to download</a>}

                    
                </Stack>
                <Stack direction="column" justify="flex-start" align="center">
                <FormControl>
                    <FormLabel>Assign To:</FormLabel>
                    
                    <AutoComplete usersOption={userOpts} handleChange={handleTicket} ticketInfo={ticketInfo.assignedTo} selectedusers={selectedusers} />
                </FormControl>
                </Stack>
                <Stack direction="column" justify="flex-start" align="center">
                <FormControl >
                    <FormLabel>Status</FormLabel>
                        <Select name="status" placeholder="status" onChange={handleTicketInfoDetails} value={ticketInfo.status}>
                                <option key={'open'}>Open</option>
                                <option key={'Assigned'}>Assigned</option>
                                <option key={'waiting'}>Waiting</option>
                                <option key={'closed'}>Closed</option>
                        </Select>
                </FormControl>
                <FormControl >
                    <FormLabel>Team Members</FormLabel>
                        <Select name="assignedTo" placeholder="team Member" onChange={handleTicketIn} value={ticketInfo.assignedTo}>
                                {users.map((user)=>{
                                    return <option key={user._id}>{user.name}</option>
                                }) }
                        </Select>
                </FormControl>
                
                {ticketInfo.problemImages.length>0 && 
                ticketInfo.problemImages.map((img)=>{
                 return (<a href={img} download ><img width="50" height="50" src={ticketInfo.problemImages[0]} alt='unable to load it' /> click here to download</a>)})}
                {selectedusers && console.log(selectedusers,'iiii')}
                

                </Stack>
            </Flex>
        </Flex>
        </>
    )
}

export default MainFacilityTickets