import {useTable,useSortBy,useGlobalFilter,useFilters,usePagination} from 'react-table'
import { useMemo
 } from 'react'
 import {
    Table,
    Thead,
    Tbody,
    Button,
    Tr,
    Th,
    Td,
    Center,
    Flex
   
  } from '@chakra-ui/react'
  import { Icon } from '@chakra-ui/react'
  import {BiUpArrow,BiDownArrow} from 'react-icons/bi'
  import { ArrowForwardIcon,ArrowBackIcon } from '@chakra-ui/icons'
import { GlobalFilter } from '../GlobalFilter'
import { ColumnFilter } from '../ColumnFilter'
import { SelectColumnFilter } from '../SelectColumnFilter'
import {useNavigate} from 'react-router-dom'
console.log(SelectColumnFilter);

const ShowFacilityTicketsTable=({DATA,COLUMNS,handleLogout})=>{

    const data = useMemo(()=>DATA,[DATA]); 
    const columns = useMemo(()=>COLUMNS,[COLUMNS])
    const navigate = useNavigate()
    

   

    // console.log(data,columns,'.....');

    const filterTypes = useMemo(
        () => ({
          
          
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )

    const defaultColumn = useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: ColumnFilter
        

        }),
        []
      )

   


    
      

    const {getTableProps,getTableBodyProps,headerGroups,prepareRow,state,setGlobalFilter,page,nextPage,previousPage,canNextPage,canPreviousPage,pageOptions} = 
    useTable({
        columns,
        data,
        defaultColumn,
        
        filterTypes
        
    },useFilters,useGlobalFilter,useSortBy,usePagination)

    const { globalFilter,pageIndex } = state;

    function Back(){
        navigate('/');
    }

    
    return (
            <>
            <Flex justify="space-around" marginTop="5px" marginBottom="5px">
            <GlobalFilter filter={globalFilter} setFilter ={setGlobalFilter } />
            <Button onClick={Back } colorScheme='teal'>Back</Button>
            <Button onClick={()=>handleLogout()} colorScheme='red'>Logout</Button>
            </Flex>
            <Table {...getTableProps()}>
                <Thead backgroundColor="rgb(204, 204, 255)" >
                    
                        { headerGroups.map((headerGroup)=>
                            (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column)=>(
                                           
                                           <Th {...column.getHeaderProps(column.getSortByToggleProps())} color='black'>
                                            {column.render('Header')}
                                            <span>
                                            { column.isSorted?(column.isSortedDesc?<Icon as={BiDownArrow} color='white' />:<Icon as={BiUpArrow} color='white'/>):'' }
                                            </span>
                                            <div>{column.canFilter ?  column.render('Filter') : null}</div>
                                           </Th>
                        ))}
                                </Tr>
                            )
                        )
                    }
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {
                        page.map(row=>{
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} >
                                    {row.cells.map((cell)=>{
                            
                                            return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>

                                            
                                    })}
                                    
                                </Tr>
                            )
                        })
                    }
                
                </Tbody>
            </Table>
            <Center>
                <span>
                    Page{' '}
                    
                    <strong>{pageIndex + 1 } of {pageOptions.length}</strong>
                </span>{' '}
                    <Button onClick={()=>{previousPage()} }isDisabled={!canPreviousPage} leftIcon = {<ArrowBackIcon />}  colorScheme='blue' variant='outline'>Previous</Button> 
                   <Button onClick={()=>{nextPage()}} isDisabled={!canNextPage} rightIcon={<ArrowForwardIcon />} colorScheme='blue' variant='outline'>Next</Button> 
            </Center>
            </>
        
    )


}

export default ShowFacilityTicketsTable