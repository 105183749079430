import { Field, FieldArray } from "formik";
import {
  FormControl,
  Grid,
  GridItem,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Center,
  Box,
  Button,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { dummyAction, SCROLLBAR_STYLES } from "../../../../constants";

function ExhibitField({ name, validate, title, helperText, disabled }) {
  return (
    <Field name={name} validate={validate}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <Grid templateColumns={"40% 1fr"}>
            <GridItem>
              <FormLabel htmlFor={name}>{title}</FormLabel>
            </GridItem>
            <GridItem>
              <Input {...field} id={name} disabled={disabled || false} />
              {form.errors[name] ? (
                <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
              ) : (
                <FormHelperText>{helperText}</FormHelperText>
              )}
            </GridItem>
          </Grid>
        </FormControl>
      )}
    </Field>
  );
}

function ExhibitActionFieldArray({ props, exhibitActionFormFields }) {
  // the name property of the field NEEDS TO REFLECT what is going on with the
  // exhibit JSON
  return (
    <FieldArray
      name="actions"
      render={(arrayHelpers) => (
        <Box>
          <Accordion overflowY={"scroll"} height={500} css={SCROLLBAR_STYLES}>
            {props.values.actions.map((action, index) => (
              <AccordionItem key={`action_${index}`}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Action: {action.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel>
                  {exhibitActionFormFields.map(
                    (exhibitActionFormField, action_index) => (
                      <ExhibitActionField
                        {...exhibitActionFormField}
                        key={`action_${index}_field_${action_index}`}
                        index={index}
                      />
                    )
                  )}
                  {/* <Button
                    onClick={() => arrayHelpers.remove(index)} // remove action from list
                  >
                    Remove This Action
                  </Button> */}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
          {/* <Center>
            <Button
              colorScheme={"teal"}
              w="80%"
              margin={5}
              onClick={() => arrayHelpers.push(dummyAction())}
            >
              Add an Action
            </Button>
          </Center> */}
        </Box>
      )}
    />
  );
}

function ExhibitActionField({ index, name, validate, title, helperText }) {
  // the name property of the field NEEDS TO REFLECT what is going on with the
  // exhibit JSON
  return (
    <Field name={`actions.${index}.${name}`} validate={validate}>
      {({ field, form }) => (
        <FormControl>
          <FormLabel htmlFor={`actions.${index}.${name}`}>{title}</FormLabel>
          <Input {...field} id={`actions.${index}.${name}`} />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </Field>
  );
}

export { ExhibitField, ExhibitActionFieldArray };
