import { useState } from "react";

import {
  
  Button,
  
  Box,
 
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
  
} from "@chakra-ui/react";
import useSwitchLightFloorPlan from "../../hooks/useSwitchLightFloorPlan";

function BrightnessSlider1({ip,Command,port}) {
    const [sliderValue, setSliderValue] = useState(50)
  
    const labelStyles = {
      mt: '2',
      ml: '-2.5',
      fontSize: 'sm',
    }
    const switchLights = useSwitchLightFloorPlan();

    
  
    return (
   
      <Box pt={6} pb={2}>
        <Slider aria-label='slider-ex-6' onChange={(val) => setSliderValue(val)}>
          <SliderMark value={25} {...labelStyles}>
            25%
          </SliderMark>
          <SliderMark value={50} {...labelStyles}>
            50%
          </SliderMark>
          <SliderMark value={75} {...labelStyles}>
            75%
          </SliderMark>
          <SliderMark
            value={sliderValue}
            textAlign='center'
            bg='blue.500'
            color='white'
            mt='-10'
            ml='-5'
            w='12'
          >
            {sliderValue}%
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Button mt={5} colorScheme="teal" onClick={()=>{switchLights(ip,Command,sliderValue,port)}}>
      Adjust Brightness
    </Button>
  
        
      </Box>
    
    )
  }

export default BrightnessSlider1
  
