import { Grid, GridItem, Heading, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { SelectInput } from "../../components/Input";
import Alerts from "./components/Alerts";
import Gallery from "./components/GalleryControls";
import useGalleries from "../../hooks/useGalleries";
import GlobalControls from "./components/GlobalControls";
import { useAuth } from "../../contexts/OAuthContext";

/*
The possible states are: [
  "Startup",
  "Shutdown",
  "Maintenance Mode",
  "None"
]

The order of authority is as such globalState > Gallery State > Screen State
Every gallery has a boolean => isConnectedToGlobalState
Evert screen has a boolean => isConnectedToGalleryState

for a gallery if isConnectedToGlobalState is true
  every screen in that gallery follows the global state =>   "Startup", "Shutdown", "Maintenance Mode", "None"
if isConnectedToGlobalState is false,
  the gallery can be in a state seperate from the global state => every screen in the gallery follows that gallery state

for a screen if isConnectedToGalleryState is true
  every screen needs to follow the gallery state =>   "Startup", "Shutdown", "Maintenance Mode", "None"
if isConnectedToGalleryState is false,
  every screen has their own state => "Startup", "Shutdown", "Maintenance Mode", "None"

So a screen will have an if-statement as such

if (screen.isConnectedToGalleryState) {
  if (gallery.isConnectedToGlobalState) {
    screen follows the global State
  } else {
    screen follows the gallery state
  }
} else {
  // screen does its own thing
}

Every screen has [isOn, isSleeping, appPersistence],
  based on the state that it is following (global, gallery, itsOwn) => these booleans are set to true/false
Every screen also has additional actions based on the screen registration... 

Every Gallery has an [isOn, isSleeping, appPersistence, areProjectorsOn, areLightsOn]
  based on the state that it is following (global, gallery) => it choose the state of its screens, projectors and lights based on these booleans

*/

const GlobalControlsPage = () => {
  const galleries = useGalleries();
  const [currentGallery, setCurrentGallery] = useState("");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
    const {isAdmin}=useAuth();

  return (
    <>
    {isAdmin?
    (
    <Grid
      w="90%"
      h="90%"
      paddingTop={10}
      templateColumns={isLargerThan1280 ? "1fr 1fr" : ""}
    >
      <GridItem>
        <Grid templateRows={"1fr 1fr"}>
          <GridItem
            w="95%"
            h="90%"
            border={"solid"}
            borderColor={"gray.200"}
            padding={5}
            margin={5}
            borderRadius={"md"}
          >
            <GlobalControls />
          </GridItem>
          <GridItem
            w="95%"
            h="90%"
            border={"solid"}
            borderColor={"gray.200"}
            padding={5}
            margin={5}
            borderRadius={"md"}
          >
            <Alerts />
          </GridItem>
        </Grid>
      </GridItem>

      <GridItem
        w="90%"
        h="90%"
        border={"solid"}
        borderColor={"gray.200"}
        padding={5}
        margin={5}
        borderRadius={"md"}
      >
        <Heading>Gallery Controls</Heading>
        <Grid>
          <GridItem>
            <SelectInput
              title={"Select Gallery"}
              value={currentGallery || "Select Gallery"}
              setValue={setCurrentGallery}
              possibleValues={galleries}
            />
          </GridItem>
          <GridItem>
            <Gallery galleryName={currentGallery} />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>):(<h2>You are not authorized to view this page</h2>)}
    </>
  );
};

export default GlobalControlsPage;
