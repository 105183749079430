import { useRef, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { IoAlertCircle } from "react-icons/io5";
import { useRequests } from "../../contexts/SocketContext";
import { SCROLLBAR_STYLES } from "../../constants";
import { Link } from "react-router-dom";

// statuses = "info" | "warning" | "success" | "error"

/*
A request is going to look like:
{
  whichScreen: "test",
  message: "trial 1",
  status: "queued",
  error: null,
  data: null,
}

the pair whichScreen, message is the key...
 */
function RequestRow({ request, removeRequest, chooseRequest }) {
  // const chakraUIStatus = getChakraUIStatus(request.status);
  console.log(request.status);
  return (
    <Alert status={request.status} variant={"left-accent"} marginBottom={1}>
      <AlertIcon />
      <AlertTitle>{request.name}</AlertTitle>
      <AlertDescription>{request.message || request.status}</AlertDescription>
      <Button marginLeft={5} onClick={() => chooseRequest(request)}>
        More...
      </Button>
      <Link to={`/screenDetails/${request.hostname}`}>
        <Button variant={"ghost"}>Go to Screen</Button>
      </Link>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={() => removeRequest(request)}
      />
    </Alert>
  );
}
function RequestModal({ request, isOpen, onClose }) {
  if (!request) {
    return null;
  }
  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {request.whichScreen} - {request.message}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{request.error ? request.error : request.data}</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function DisplayRequests() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const { requests, removeTrackingRequest } = useRequests();
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();
  const [chosenRequest, setChosenRequest] = useState(null);

  function chooseRequest(req) {
    setChosenRequest(req);
    modalOnOpen();
  }

  return (
    <>
      {requests.length > 0 && (
        <Button
          leftIcon={<IoAlertCircle />}
          ref={btnRef}
          colorScheme="green"
          w="100%"
          onClick={onOpen}
          borderRadius={0}
          margin={1}
        >
          Requests: {requests.length}
        </Button>
      )}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Requests</DrawerHeader>
          <DrawerBody>
            <Box
              flexDirection={"column"}
              overflowY="scroll"
              h="500px"
              css={SCROLLBAR_STYLES}
            >
              {requests.map((request, index) => (
                <RequestRow
                  key={`alert_${index}`}
                  request={request}
                  chooseRequest={chooseRequest}
                  removeRequest={removeTrackingRequest}
                />
              ))}
            </Box>
            <RequestModal
              request={chosenRequest}
              isOpen={modalIsOpen}
              onOpen={modalOnOpen}
              onClose={modalOnClose}
            />
          </DrawerBody>
          <DrawerFooter position={"absolute"} bottom={0} right={0}>
            <Button colorScheme={"teal"} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DisplayRequests;
