
import {
    createContext,
    useContext,
    useEffect,
    useState
    
    
  } from "react";

import { SERVER_URL } from "../constants";
import axios from "axios";

const OAuthContext = createContext()

function OAuthProvider({children}){

const [user,setUser] =useState();
const [isAdmin,setIsAdmin]=useState(true);

useEffect(()=>{

    if(document.cookie){
        console.log('cookiehere');
        const loggedIn = document.cookie
  .split('; ')
  .find((row) => row.startsWith('loggedIn'))
  ?.split('=')[1];
  if(loggedIn){
    setUser(true);
    

}
    }
},[])



function Login(){

    window.open(`${SERVER_URL}/api/v1/auth/microsoft`,"_self")
    const loggedIn = document.cookie
  .split('; ')
  .find((row) => row.startsWith('loggedIn'))
  ?.split('=')[1];
  console.log('loggedIn',loggedIn);

    if(loggedIn){
        setUser(true);
        return true;

    }

}
            

async function Logout(){
    try{
    const isLoggedout = await axios.post(`${SERVER_URL}/api/v1/logout`,{withCredentials:true});
    if(isLoggedout){
        setUser(false);
    }
}catch(err){
    console.log(err);
}

}

function makeAdmin(value){
    setIsAdmin(value)
}

return (
    <OAuthContext.Provider value={{user,Login,Logout,isAdmin,makeAdmin}}>
        {children}
    </OAuthContext.Provider>
)

}



const useAuth = ()=>useContext(OAuthContext);
const useUser = ()=>useContext(OAuthContext).user;


export {
    useAuth,
    useUser,
    OAuthContext
}




export default OAuthProvider;

