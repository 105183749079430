import React  from 'react'
import {

    Button,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from "@chakra-ui/react";
import BrightnessSlider1 from '../BrightnessSlider1';
import { useState } from 'react';
import { SunIcon } from "@chakra-ui/icons";


const PopupForLights= function({onShowpopup,onClosepopup,onLight,offLight,id,customizedname,handleChange,debounceHandler,lightObj}){
    const [show,setShow]=useState(false);

    function showBrightnessSlider(){
      setShow(!!!show);
    }

    return(
        <>
        <AlertDialog isOpen = {onShowpopup} onClose = {onClosepopup} autoFocus={false}>
     <AlertDialogOverlay>
       <AlertDialogContent>
         <AlertDialogHeader fontSize='lg' fontWeight='bold'>
           Lights
         </AlertDialogHeader>

         <AlertDialogBody>
         <Input type="text" id={id} name="customizedname" value={customizedname} onChange={(e)=>{handleChange(e)}} onBlur={e=>{debounceHandler(e)}}/>

         </AlertDialogBody>

         <AlertDialogFooter>

           <Button  onClick={()=>{onClosepopup()}}>
             Cancel
           </Button>
           <Button colorScheme='red' onClick={onLight} ml={3}>
             on
           </Button>
           <Button colorScheme='red' onClick={offLight} ml={3}>
             off
           </Button>
           <Button leftIcon={<SunIcon />} colorScheme='yellow' variant="solid" onClick={()=>{showBrightnessSlider()}}></Button>

           {show&&<BrightnessSlider1 ip={lightObj['IPAddress']} Command={lightObj['Command']} port={lightObj['port']} />}

         </AlertDialogFooter>
       </AlertDialogContent>
     </AlertDialogOverlay>
   </AlertDialog>
        </>
    )
}
export default PopupForLights;
