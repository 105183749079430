import { Heading, Flex, Box } from "@chakra-ui/react";
import AlertRow from "../../../../components/DisplayAlerts/AlertRow";
import { SCROLLBAR_STYLES } from "../../../../constants";
import { useAlerts } from "../../../../contexts/SocketContext";

const Alerts = () => {
  const { alerts, removeAlert } = useAlerts();

  return (
    <>
      <Heading>Alerts</Heading>
      <Flex
        flexDirection={"column"}
        overflowY="scroll"
        h="200px"
        css={SCROLLBAR_STYLES}
      >
        {alerts.map((alert, index) => (
          <Box marginBottom={1} h={"50px"} key={`alert_${index}`}>
            <AlertRow alert={alert} removeAlert={removeAlert} />
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default Alerts;
