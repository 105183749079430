import axios from "axios";
import { SERVER_URL } from "../constants";
import { useRequests } from "../contexts/SocketContext";

async function _changeState(type, name, newState) {
  console.log("state on-changed to", newState);
  try {
    const res = await axios.post(`${SERVER_URL}/state/setState`, {
      type,
      name,
      newState,
    });
    console.log(res.data);
    return [null, res.data];
  } catch (error) {
    console.log({ error });
    return [{ message: error.message || "Could not change state" }, null];
  }
}

async function _changeSleep(type, name, sleep) {
  console.log("sleep on-changed to", sleep);
  try {
    const res = await axios.post(`${SERVER_URL}/state/setSleep`, {
      type,
      name,
      sleep,
    });
    console.log(res.data);
    return [null, res.data];
  } catch (error) {
    console.log({ error });
    return [{ message: error.message || "Could not change sleep" }, null];
  }
}

async function _changeAppPersistence(type, name, appPersistence) {
  console.log("app persistence on-changed to", appPersistence);
  try {
    const res = await axios.post(`${SERVER_URL}/state/setAppPersistence`, {
      type,
      name,
      appPersistence,
    });
    console.log(res.data);
    return [null, res.data];
  } catch (error) {
    console.log({ error });
    return [
      { message: error.message || "Could not change app persistence" },
      null,
    ];
  }
}

const useChangeState = () => {
  const { queueNewRequest } = useRequests();
  async function changeState(type, name, newState, hostname) {
    // when there is a hostname, we use the hostname, else we use the name in the request
    const [err, data] = await _changeState(type, hostname || name, newState);
    console.log({ data });
    if (!err) {
      queueNewRequest(
        name,
        hostname || name,
        data.message,
        data.success || null,
        data.error || null
      );
    }
  }
  async function changeSleep(type, name, sleep, hostname) {
    // when there is a hostname, we use the hostname, else we use the name in the request
    const [err, data] = await _changeSleep(type, hostname || name, sleep);
    if (!err) {
      queueNewRequest(
        name,
        hostname || name,
        data.message,
        data.success || null,
        data.error || null
      );
    }
  }

  async function changeAppPersistence(type, name, appPersistence, hostname) {
    // when there is a hostname, we use the hostname, else we use the name in the request
    const [err, data] = await _changeAppPersistence(
      type,
      hostname || name,
      appPersistence
    );
    if (!err) {
      queueNewRequest(
        name,
        hostname || name,
        data.message,
        data.success || null,
        data.error || null
      );
    }
  }
  return {
    changeAppPersistence,
    changeSleep,
    changeState,
  };
};

export default useChangeState;
