import { useMemo } from "react"
import { Select } from "@chakra-ui/react"

export function SelectColumnArrayFilter({
    column: { filterValue, setFilter, preFilteredRows, id }
  }) {
    
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        // console.log(row,'scf');
        // console.log(options)
        // console.log(row.values[id],'rowvalues')
        if(row.values[id].length>1){
          row.values[id].forEach((value)=>{
            options.add(value)
          })
        }
        
        else {options.add(...row.values[id])}
        console.log(options,'after');
      })
      console.log(options,'opt');
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <Select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Select>
    )
  }