import {
  Center,
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  Grid,
  GridItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Popup from './popup/index'
import { SERVER_URL } from "../../../../constants";
import {
  exhibitFormFields,
  exhibitActionFormFields,
  exhibitThresholdFormFields,
} from "../../ScreenRegistrationFormFields";
import { ExhibitActionFieldArray, ExhibitField } from "../ExhibitFormFields";

function ScreenRegisterForm({ initialValues, setShowDetails, hostname }) {
  const [showpopup,setShowpopup] = useState(false)

  const onShowpopup = ()=>{
     setShowpopup(!showpopup);
     console.log(showpopup);
     console.log('Ive been called');
     
     
     
}


  const onClosepopup=()=>{
    setShowpopup(!showpopup);
  }
  async function deleteScreen() {
    try {
      const res = await axios.delete(`${SERVER_URL}/screen/delete/${hostname}`);
      console.log("deleting screen", res.data);
    } catch (e) {
      console.log(e);
    }
    setShowDetails(false);
  }

  return (
   <div>
    <Formik
      initialValues={initialValues}
      validate={(values) => {} /** global validate */}
      onSubmit={async (values, actions) => {
        try {
          const res = await axios.post(`${SERVER_URL}/screen/save`, {
            screen: values,
          });
          console.log("submitted new value", res.data);
        } catch (e) {
          actions.setErrors(e.message);
        }
        actions.setSubmitting(false);
      }}
    >
      {(props) => (
        <Form>
          <Center w="100%" h="100%" paddingTop={10}>
            <Tabs w="90%" h="90%" isFitted>
              <TabList>
                <Tab>Screen Info</Tab>
                <Tab>Actions</Tab>
                <Tab>Submit</Tab>
              </TabList>

              <TabPanels>
                <TabPanel
                  border={"solid"}
                  borderColor={"gray.200"}
                  padding={5}
                  margin={5}
                  borderRadius={"md"}
                >
                  {exhibitFormFields.map((exhibitFormField, index) => (
                    <ExhibitField key={`form_${index}`} {...exhibitFormField} />
                  ))}
                </TabPanel>

                <TabPanel
                  border={"solid"}
                  borderColor={"gray.200"}
                  padding={5}
                  margin={5}
                  borderRadius={"md"}
                >
                  <ExhibitActionFieldArray
                    props={props}
                    exhibitActionFormFields={exhibitActionFormFields}
                  />
                </TabPanel>
                <TabPanel
                  border={"solid"}
                  borderColor={"gray.200"}
                  padding={5}
                  margin={5}
                  borderRadius={"md"}
                >
                  {exhibitThresholdFormFields.map(
                    (exhibitThresholdFormField, threshold_index) => (
                      <ExhibitField
                        {...exhibitThresholdFormField}
                        key={`form_threshold_${threshold_index}`}
                      />
                    )
                  )}
                  <Grid templateColumns={"10% 10% 10% 1fr"}>
                    <GridItem>
                      <Button
                        mt={4}
                        colorScheme="teal"
                        isLoading={props.isSubmitting}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        mt={4}
                        colorScheme="red"
                        type="button"
                        onClick={onShowpopup}
                      >
                        Delete
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button mt={4} onClick={() => setShowDetails(false)}>
                        Back
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Alert status="info" borderRadius={"md"} margin={2}>
                        <AlertIcon />
                        <AlertDescription>
                          Also confirm additional actions that this exhibit can
                          do
                        </AlertDescription>
                      </Alert>
                    </GridItem>
                  </Grid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Center>
        </Form>
      )}
    </Formik>
    {onShowpopup && <Popup onShowpopup = {showpopup} onClosepopup={onClosepopup} deleteScreen ={deleteScreen}></Popup> }  
 </div>
  );
}

export default ScreenRegisterForm;
