import axios from "axios"
import { useState,useEffect} from "react"
import { SERVER_URL } from "../../constants"
import {Flex,FormControl,FormLabel,Input,Stack,Button, HStack} from "@chakra-ui/react"
import { Link, useParams } from "react-router-dom"

export default function EditExhibit(){
    const [error,setError]=useState(false);
    const [message,setMessage]=useState('')
    const [exhibitInfo,setExhibitInfo]=useState({

        name:'',
        gallery:''
    })
    const [exhibitInfoDuplicate,setExhibitInfoDuplicate]=useState({

        name:'',
        gallery:''
    })
    const {id} = useParams();

    useEffect(()=>{
        let mounted = true;
        async function getData(){
        try{
        const result = await axios.get(`${SERVER_URL}/api/v1/exhibit/${id}`,{withCredentials:true})
        if(result){
                console.log(result.data);
                setExhibitInfo({
                    ...exhibitInfo,
                    name:result.data.exhibit.name,
                    gallery:result.data.exhibit.gallery
                })

                setExhibitInfoDuplicate({
                    ...exhibitInfoDuplicate,
                    name:result.data.exhibit.name,
                    gallery:result.data.exhibit.gallery

                })


        }
        }
        catch(err){
                setError(true)
                setMessage(err.message)
        }
    }
        mounted&&getData();

        return (()=>{
            mounted = false;
        })

        
},[]);


    function handleExhibitChange(e){
            setError(false);
            setExhibitInfo({
                ...exhibitInfo,
                [e.target.name]:e.target.value
            })
    }

    async function editExhibit(e){
        e.preventDefault();
        try{
            const isUpdated = await axios.put(`${SERVER_URL}/api/v1/updateexhibit/${id}`,exhibitInfo,{withCredentials:true});
            if(isUpdated){
                setMessage('Exhibit updated succesfully');
                
                

            }
        }
        catch(err){
            setError(!error);
            setMessage(err.message);
        }

    }

    function handleCancel(e){
        e.preventDefault();

        setExhibitInfo({
            ...exhibitInfo,
            ...exhibitInfoDuplicate
        })
    }

    


    return (
        <>
            <Flex width="100vw" height="100vh" justifyContent ="space-around" alignItems="center" direction="column"  bgColor="rgb(0, 82, 204)">
                <Flex direction="column" alignItems="center" justifyContent="center" bgColor="white" borderWidth={1} borderRadius={15} boxShadow="lg">
                    <Stack direction={["column","row"]} spacing="10px"margin="5px" >
                    <FormControl isRequired>
                                <FormLabel>Gallery</FormLabel>
                                
                                    
                                    
                                    <Input type="text" name="gallery" variant='outline' placeholder='Health' value={exhibitInfo.gallery}  onChange={(event)=>{handleExhibitChange(event)}}/>
                                
                               
                    </FormControl>
                        <FormControl isRequired>
                                <FormLabel>ExhibitName</FormLabel>
                                
                                    
                                    
                                    
                                    <Input type="text" name="name" variant='outline' placeholder='FredtheRobot' value={exhibitInfo.name}  onChange={(event)=>{handleExhibitChange(event)}}/>
                                
                            
                        </FormControl>
                    </Stack>
                    <HStack>
                    <Button onClick={(event)=>editExhibit(event)} colorScheme="green">
                        Update
                    </Button>
                    <Button onClick={(event)=>handleCancel(event)}>Cancel</Button>
                    </HStack>


                </Flex>
                { (error||message)&&<h2>{message}</h2>}
                <Link to='/exhibits'><Button>Back</Button></Link>
            </Flex>
        </>
    )
}