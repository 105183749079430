import axios from "axios"

import { useState} from "react"
import { SERVER_URL } from "../../constants"
import {Flex,FormControl,FormLabel,Input,Stack,Button} from "@chakra-ui/react"
import { Link } from "react-router-dom"
export default function AddFacility(){
    const [error,setError]=useState(false);
    const [message,setMessage]=useState('')
    const [facilityInfo,setFacilityInfo]=useState({
        buildingArea:'',
        buildingLevel:''
    })


    function handleFacilityChange(e){
            setError(false);
            setMessage('');
            setFacilityInfo({
                ...facilityInfo,
                [e.target.name]:e.target.value
            })
    }

    function MakeNamesUniqueStyle(p){
        
        let p_updated = p.trim();
        const found = p_updated.match(/^./);
        
        let c = found[0].toUpperCase()+p_updated.slice(1).toLowerCase();
        return c
    }

    async function addFacility(e){
        e.preventDefault();
        try{
            facilityInfo.buildingLevel=MakeNamesUniqueStyle(facilityInfo.buildingLevel);
            const isAdded = await axios.post(`${SERVER_URL}/api/v1/addfacility`,facilityInfo,{withCredentials:true});
            if(isAdded){
                setMessage('Facility added succesfully');
                setFacilityInfo({
                   
                    buildingArea:'',
                    buildingLevel:''
                })
                

            }
        }
        catch(err){
            setError(true);
            setMessage(err.message);
        }

    }

    


    return (
        <>
            <Flex width="100vw" height="100vh" justifyContent ="space-around" alignItems="center" direction="column"  bgColor="rgb(204, 204, 255)">
                <Flex direction="column" alignItems="center" justifyContent="center" bgColor="white" borderWidth={1} borderRadius={15} boxShadow="lg">
                    <Stack direction={["column","row"]} spacing="10px"margin="5px" >
                    <FormControl isRequired>
                                <FormLabel>BuildingLevel</FormLabel>
                                
                                    
                                    
                                    <Input type="text" name="buildingLevel" variant='outline' placeholder='Level 0' value={facilityInfo.buildingLevel}  onChange={(event)=>{handleFacilityChange(event)}}/>
                                
                               
                    </FormControl>
                        <FormControl isRequired>
                                <FormLabel>BuidlingArea</FormLabel>
                                
                                    
                                    
                                    
                                    <Input type="text" name="buildingArea" variant='outline' placeholder='InnovationLab' value={facilityInfo.buildingArea}  onChange={(event)=>{handleFacilityChange(event)}}/>
                                
                            
                        </FormControl>
                    </Stack>
                    <Button onClick={(event)=>addFacility(event)} colorScheme="green">
                        Add
                    </Button>

                </Flex>
                { (error||message)&&<h2>{message}</h2>}
                <Link to='/facilities'><Button>Back</Button></Link>
            </Flex>
        </>
    )
}
