export default function remove_duplicates(arr) {
  const res = [];
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    // pick the last occurence of obj only
    let hasAnotherOneLater = false;
    for (let j = i + 1; j < arr.length; j++) {
      const other_obj = arr[j];
      if (obj.equals(other_obj)) {
        hasAnotherOneLater = true;
        break;
      }
    }
    if (!hasAnotherOneLater) {
      res.push(obj);
    }
  }
  return res;
}
