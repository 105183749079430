import { useState } from "react";
import { SERVER_URL } from "../../../../constants";
import {
  Center,
  FormControl,
  Grid,
  GridItem,
  Input,
  Button,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";

function ScreenRegMenu({ setShowDetails, setInitialValues }) {
  const [hostname, setHostname] = useState(useParams().hostname || "");
  const [errorMsg, setErrorMsg] = useState("");

  async function findScreen() {
    try {
      const res = await axios.get(`${SERVER_URL}/screen/${hostname}`);
      if (res.data.found) {
        // console.log({ screen: res.data.screen });
        setInitialValues(res.data.screen);
        setShowDetails(true);
      } else {
        setErrorMsg("Screen not found");
      }
    } catch (e) {
      setErrorMsg(e.message || "Screen not found");
    }
  }findScreen();
  return (
    /*<Center h="100%" w="100%">
      <FormControl h="50vh" w="50vw" isInvalid={errorMsg.length !== 0}>
        <Grid templateRows={"50% 50%"}>
          <GridItem>Hostname</GridItem>
          <GridItem>
            <Input
              borderColor={"teal.300"}
              value={hostname}
              id="name"
              onChange={(e) => setHostname(e.target.value)}
            />
          </GridItem>
        </Grid>

        <FormHelperText>
          Enter an exhibit hostname to get more information about it
        </FormHelperText>
        <FormErrorMessage>{errorMsg}</FormErrorMessage>
        <Button
          marginTop="5"
          colorScheme="teal"
          variant="solid"
          onClick={findScreen}
        >
          Find Screen
        </Button>
      </FormControl>
    </Center>*/
 null );
}

export default ScreenRegMenu;
