import { useState, useEffect } from "react";
import { SERVER_URL, POSSIBLE_STATES } from "../../../../constants";
import useChangeState from "../../../../hooks/useChangeState";
import { Flex, Heading } from "@chakra-ui/react";
import { SelectInput, SwitchInput } from "../../../../components/Input";
import axios from "axios";

const ScreenStateControls = ({ name, hostname }) => {
  const [stateValue, setStateValue] = useState(POSSIBLE_STATES[0]);
  const [screenState, setScreenState] = useState(false);
  const [appPersistence, setAppPersistence] = useState(false);
  const { changeState, changeAppPersistence, changeSleep } = useChangeState();

  useEffect(() => {
    async function getScreenStateDetails() {
      const res = await axios.get(
        `${SERVER_URL}/state/get/screen?name=${hostname}`
      );
      console.log({ state: res.data.state });
      setStateValue(res.data.state);
      setScreenState(res.data.sleep);
      setAppPersistence(res.data.appPersistence);
    }

    getScreenStateDetails();
  }, [hostname]);

  return (
    <Flex h="100%" w="90%" flexDirection={"column"} justify="space-between">
      <Heading size={"md"}>State Details</Heading>
      <SelectInput
        title={"Current State"}
        value={stateValue}
        setValue={setStateValue}
        possibleValues={POSSIBLE_STATES}
        onChange={(e) => changeState("screen", name, e.target.value, hostname)}
      />

      <SwitchInput
        title={"Current Sleep State"}
        value={screenState}
        setValue={setScreenState}
        onChange={(this_sleep) =>
          changeSleep("screen", name, this_sleep, hostname)
        }
      />

      <SwitchInput
        value={appPersistence}
        setValue={setAppPersistence}
        title={"App Persistence"}
        onChange={(this_appPersistence) =>
          changeAppPersistence("screen", name, this_appPersistence, hostname)
        }
      />
    </Flex>
  );
};

export default ScreenStateControls;
