import React, { useEffect } from "react";
import {
  Text,
  Center,
  VStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  Container,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SyncedScreenshot from "../../../../components/SyncedScreenShot";
import { useRef, useState } from "react";
import Actions from "../../../../components/filteredActions";
import axios from "axios";
import { SERVER_URL } from "../../../../constants";
import { Carousel } from "react-responsive-carousel";

const ScreenshotCard = ({ name, hostname, isProblematic }) => {
  const initialFocusRef = useRef();
  const [numScreens, setNumScreens] = useState(1);

  useEffect(() => {
    async function getNumScreens() {
      const res = await axios.get(
        `${SERVER_URL}/screen/${hostname}/numScreens`
      );
      setNumScreens(res.data.numScreens || 1);
    }
    getNumScreens();
  }, [hostname]);
  return (
    <Popover
      initialFocusRef={initialFocusRef}
      closeOnBlur={true}
      placement="right"
    >
      <PopoverTrigger>
        <Center
          label="Click for more info"
          fontSize="md"
          bg="inherit"
          color="black"
          w="25em"
          h="25em"
        >
          <VStack
            w="25em"
            h="25em"
            padding={"2px"}
            margin={"2px"}
            background={isProblematic ? "red.100" : "gray.100"}
            border={"2px solid"}
            borderColor="gray.300"
            borderRadius={"5%"}
          >
            <Carousel
              width={"25em"}
              autoPlay={true}
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
            >
              {new Array(numScreens).fill(1).map((elem, i) => (
                <Container w="23em" h="22em" key={`${hostname}_${i}`}>
                  <SyncedScreenshot
                    hostname={`${hostname}_${i}`}
                    w="23em"
                    h="22em"
                  />
                </Container>
              ))}
            </Carousel>

            <Text padding={"2px"} isTruncated>
              {name}
            </Text>
          </VStack>
        </Center>
      </PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody display={"flex"} flexDirection="column" padding={0}>
            <Actions name={name} hostname={hostname} />
            <Link to={`/screenDetails/${hostname}`}>
              <Button margin={"1 0"} w="100%" colorScheme={"teal"}>
                Go to screen page
              </Button>
            </Link>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
export default ScreenshotCard;
