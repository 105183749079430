import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Center,
  Grid,
  GridItem,
  Heading,
  Flex,
  Button,
  Container,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Wrap,
  useMediaQuery,
} from "@chakra-ui/react";
import SyncedScreenshot from "../../components/SyncedScreenShot";
import Monitor from "./components/Monitor";
import Actions from "../../components/Actions";
import axios from "axios";
import Results from "./components/Results";
import { Carousel } from "react-responsive-carousel";
import { SERVER_URL } from "../../constants";
import ScreenStateControls from "./components/ScreenStateControls";
import CMDEditor from "./components/CMDEditor";

const ScreenDetails = () => {
  const { hostname } = useParams();
  const [screen, setScreen] = useState(null);
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    async function getScreen() {
      try {
        const res = await axios.get(`${SERVER_URL}/screen/${hostname}`);
        console.log({ screen: res.data.screen });
        setScreen(res.data.screen);
      } catch (error) {
        console.log("in fetching screen details", { error });
      }
    }
    getScreen();
  }, [hostname]);

  if (!screen) {
    return (
      <Center w="100%" h="100%">
        Loading details for screen "{hostname}"...
      </Center>
    );
  }

  return (
    <Grid w="98vw" h="92vh" templateColumns={isLargerThan1280 ? "60% 1fr" : ""}>
      <GridItem>
        <Grid templateRows={"1fr 40%"} h="100%">
          <GridItem
            border={"solid"}
            borderColor={"gray.200"}
            padding={2}
            margin={2}
            borderRadius={"md"}
            width={"95%"}
            height={"95%"}
          >
            <Carousel
              width={"100%"}
              showStatus={false}
              autoPlay={true}
              showThumbs={false}
              infiniteLoop={true}
            >
              {new Array(screen.numScreens || 0).fill(1).map((elem, i) => (
                <Container w="100%" h="55vh" maxWidth="95%" key={`${hostname}_${i}`}>
                  <SyncedScreenshot
                    hostname={`${hostname}_${i}`}
                    w="100%"
                    h="55vh"
                    maxWidth="95%"
                  />
                </Container>
              ))}
            </Carousel>
          </GridItem>
          <GridItem>
            <Grid templateColumns={isLargerThan1280 ? "40% 1fr" : ""} h="100%">
              <GridItem
                border={"solid"}
                borderColor={"gray.200"}
                padding={2}
                margin={2}
                borderRadius={"md"}
              >
                <Flex
                  h="100%"
                  w="90%"
                  flexDirection={"column"}
                  justify="space-between"
                >
                  <Heading fontSize="lg">
                    Screen {screen.name} from Gallery {screen.gallery}
                  </Heading>
                  <Link to={`/VNC/${hostname}`}>
                    <Button colorScheme="linkedin" w="100%">
                      Open VNC Session
                    </Button>
                  </Link>
                  <Link to={`/registerScreen/${hostname}`}>
                    <Button colorScheme="teal" w="100%">
                      Change Details
                    </Button>
                  </Link>
                </Flex>
              </GridItem>
              <GridItem
                border={"solid"}
                borderColor={"gray.200"}
                padding={2}
                margin={2}
                borderRadius={"md"}
              >
                <ScreenStateControls name={screen.name} hostname={hostname} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem>
        <Grid w="100%" h="92vh" templateRows={"1fr 1fr"}>
          <GridItem>
            <Monitor hostname={hostname} />
          </GridItem>
          <GridItem
            border={"solid"}
            borderColor={"gray.200"}
            padding={2}
            margin={2}
            borderRadius={"md"}
            w={"90%"}
            h={"90%"}
          >
            <Tabs>
              <TabList>
                <Tab>Actions</Tab>
                <Tab>Results</Tab>
                <Tab>CMD Editor</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Wrap>
                    <Actions hostname={hostname} />
                  </Wrap>
                </TabPanel>
                <TabPanel>
                  <Results hostname={hostname} />
                </TabPanel>
                <TabPanel>
                  <CMDEditor name={screen.name} hostname={hostname} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default ScreenDetails;
